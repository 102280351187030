.delete-btn {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.delete-btn-title, .delete-btn-count {
    margin: 0;
    color: #EC5252;
    font-family: "Dmsans500";
    font-size: 14px;
    font-style: normal;
}

.delete-btn-img {
    border-radius: 3px;
    background: #EC5252;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}