.add-report-container {
    position: absolute;
    padding: 15px 15px;
    width: 380px;
    z-index: 100;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14);
}

.add-report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-report-img {
    width: 12px;
}

.add-report-title {
    margin: 0;
    margin-left: 5px;
    color: #1B2559;
    font-family: "Poppins500";
    font-size: 14px;
    letter-spacing: -0.18px;
}

.see-all-reports {
    margin: 0;
    margin-right: 4px;
    color: #1B2559;
    font-family: "Poppins700";
    font-size: 10px;
    letter-spacing: -0.1px;
}

.add-report-inputs {
    margin-top: 15px;
    position: relative;
}

.add-report-text {
    padding: 10px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #EFEFEF;
    background: transparent;
    outline: none;
    font-family: "Poppins300";
    font-size: 12px;
    letter-spacing: -0.2px;
}
.add-report-text::placeholder {
    color: rgba(173, 173, 173, 0.70);
    font-family: "Poppins400";
}

.add-report-button {
    padding: 8px 22px;
    background: #1B2559;
    border-radius: 0px 1px 1px 0px;
    position: absolute;
    height: 92%;
    top: 50%;
    transform: translateY(-50%);
    right: 1px;
    color: #FFF;
    font-family: "Poppins500";
    font-size: 12px;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    border: none;
    transition: .3s ease;
}
.add-report-button:hover {
    padding-right: 30px;
}

.add-report-break {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    color: #575757;
    font-family: "Poppins400";
    font-size: 12px;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    text-align: center;
}

.add-report-break  .row {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
}

.add-report-line {
    height: 1px;
    background-color: #EFEFEF;
}

.add-report-existing {
    margin: 15px 0;
    color: #1B2559;
    font-family: "Poppins500";
    font-size: 14px;
    letter-spacing: -0.28px;
}

.add-report-btns {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #EFEFEF;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.add-report-checks {
    margin-top: 15px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    height: 120px;
    overflow-y: scroll;
}

.add-report-content {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.add-report-label {
    color: #303030;
    font-family: "Poppins400";
    font-size: 12px;
    letter-spacing: -0.14px;
    text-transform: capitalize;
}

.add-report-checks::-webkit-scrollbar {
    width: 10px;
}
.add-report-checks::-webkit-scrollbar-track {
    background: #D9D9D9; 
}
.add-report-checks::-webkit-scrollbar-thumb {
    background: #A6A6A6; 
}
.add-report-checks::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.add-report-cancle {
    color: #828282;
    font-family: "Poppins400";
    font-size: 12px;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    background: none;
    border: none;
    transition: .2s ease;
    padding: 5px 10px;
    border-radius: 3px;
}

.add-report-save {
    padding: 5px 10px;
    border-radius: 3px;
    color: #1B2559;
    font-family: "Poppins500";
    font-size: 12px;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    background: none;
    border: none;
    transition: .2s ease;
}

.add-report-cancle:hover {
    background: #f8f8f8;
}
.add-report-save:hover {
    background: #f8f8f8;
}

.see-all-reports-link {
    display: flex;
    gap: 1px;
}
.progress-loading{
    display: flex;
    justify-content: center;
    margin: 35px;
}

.load-more {
    border-radius: 3px;
    color: #1B2559;
    font-family: "Poppins500";
    font-size: 11px;
    letter-spacing: -0.14px;
    background: none;
    border: none;
    transition: .2s ease;
    margin-top: 10px;
}