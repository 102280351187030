.chat-feature {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chat-feature-title {
    text-align: center;
    margin-top: 10px;
    color: #686868;
    font-family: "Inter500";
    font-size: 16px;
}

.chat-feature-para {
    color: #686868;
    text-align: center;
    font-family: "Inter300";
    font-size: 14px;
    margin-top: 10px !important;
    line-height: 24px;
}

.chat-feature svg {
    width: 20px;
    height: 20px;
}

@media (max-width: 768px) {
    .chat-feature-para {
        font-size: 13px;
        line-height: 22px;
    }
}

@media (max-width: 667px) {
    .chat-feature-para {
        font-size: 11px;
        line-height: 19px;
    }
    .chat-feature-title {
        font-size: 14px;
    }
}

@media (max-width: 576px) {

}