.contact-us{ 
    width: 100% !important;
    position: relative;
}

.cotact-us-container {
    margin-top: 90px;
    padding-top: 90px;
    background-image: repeating-linear-gradient(
        to right,
        #A5A5A5,
        #A5A5A5 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
}

.contact-us-para {
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.62px;
}

.contact-input {
    margin-top: 18px !important;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.contact-input-title {
    margin-bottom: 5px;
    color: #000;
    font-family: "Poppins400";
    font-size: 14px;
}

.contact-input-text {
    box-shadow: 0px 2px 2px 2px rgba(204, 204, 204, 0.10);
    width: 100%;
    padding: 8px 15px 12px;
    border: 1px solid #cccccc;
    resize: none;
}
.contact-input-text::placeholder {
    color: #CCC;
    font-family: "Poppins400";
    font-size: 12px;
}

.contact-submit {
    width: 100%;
    background: var(--main-color);
    color: #fff;
    padding: 12px;
    font-family: "Poppins500";
    font-size: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    text-align: center;
    outline: none;
    border: none;
    margin-top: 25px;
    transition: .3s ease;
    border: 1px solid var(--main-color);
}
.contact-submit:hover {
    background: #fff;
    color: var(--main-color);
}

.contact-us-img {
    position: absolute;
    right: 0;
    max-width: 58%;
    top: 65%;
    transform: translateY(-50%);
    object-fit: contain;
}

.PhoneInputInput::placeholder {
    color: #CCC !important;
    font-family: "Poppins400" !important;
    font-size: 12px !important;
}

.contact-us-img-responsive {
    display: none;
}
.contact-btn {
    margin-top:30px;
}
.success-message p{
    line-height: 30px;
    margin-top: 20px;
}
.success-message h2{
    margin-top: 15px;
}

@media (max-width: 1200px) {
    .contact-us-para {
        font-size: 15px;
        line-height: 26px;
    }
    .contact-input-title {
        font-size: 13px;
    }
}

@media (max-width: 992px) {
    .contact-us-img {
        display: none;
    }
    .contact-us-img-responsive {
        margin-top: 40px;
        display: block;
    }
    .contact-us-img-responsive img {
        width: 100%;
        object-fit: contain;
    }
    .cotact-us-container {
        padding-top: 55px;
    }
}
    


@media (max-width: 768px) {
    .contact-us-para {
        font-size: 14px;
        line-height: 25px;
    }
}

@media (max-width: 576px) {
    .contact-us-para {
        font-size: 13px;
        line-height: 24px;
    }
    .contact-input-title {
        font-size: 12px;
    }
    .contact-submit {
        font-size: 12px;
        padding: 10px;
    }
}

@media (max-width: 476px) {
    .contact-us-para {
        font-size: 12px;
        line-height: 23px;
    }
}
