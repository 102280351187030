.email-verified-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: rgba(97, 175, 100, 0.19);
    width: 56px;
    height: 54px;
    padding: 15px 0;
    margin: 0 auto;
}