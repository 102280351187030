.pagination {
    display: flex !important;
    justify-content: center !important;
}

.page-item .page-link {
    margin: 0 10px;
    border: 1px solid #BEBEBE;
    background: transparent;
    color: #A4A4A4;
    border-radius: 4px;
    display: flex;
    font-family: "Inter600";
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.active>.page-link, .page-link.active {
    color: #636363 !important;
    border: unset !important;
    background: rgba(45, 176, 216, 0.22) !important;   
}

.page-link:hover {
    z-index: 2;
    color: #000 !important;
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
    font-family: "Inter700";
}

/* @media (max-width: 576px) {
    .page-item .page-link {
        font-size: 12px;
        margin: 0 10px;
        width: 35px;
        height: 35px;
    }
} */