.dashboard {
    padding: 40px 30px;
}

.top-dashboard-section {
    width: 100% !important;
}

.ai-btn-container {
    position: fixed;
    bottom: 40px;
    right: 41px;
    z-index: 1200;
}
.dashboard .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    align-items: unset !important;
}

@media (max-width: 1440px) {
    .dashboard {
        margin-left: 15px !important;
    }
}

@media (max-width: 1200px) {
    .top-dashboard-section {
        width: 100% !important;
        margin: 0 auto;
    }
    .bottom-dashboard-section {
        width: 100% !important;
        margin: 0 auto;
    }
    .dashboard {
        padding: 40px 0 !important;
        margin-left: unset !important;
    }
}

@media (max-width: 992px) {
    .dashboard {
        padding: 40px 60px 40px 20px;
    }   
}

@media (max-width: 828px) {
    .top-dashboard-section {
        width: 100% !important;
    }
    .bottom-dashboard-section {
        width: 100% !important;;
    }
    .dashboard {
        padding: 40px 0 40px 20px;
    }   
}

@media (max-width: 768px) {
    .dashboard {
        padding: 40px 0
    }   
    .dashboard .note-content {
        padding-top: 20px !important;
    }
}

@media (max-width: 576px) {
    .dashboard {
        padding: unset !important;
    }
    .top-dashboard-section {
        width: 100% !important;
        margin: 100px 0 0 0 !important;
        padding: 0 !important;
    }
    .bottom-dashboard-section {
        width: 100% !important;
        margin: 60px 0 0 0 !important;
        margin-top: 18px !important;
        padding-top: unset;
    }
}

@media (max-width: 476px) {
    .ai-btn-container {
        bottom: 30px;
        right: 13px;
    }
}