
.App {
    max-width: 100%;
    overflow: clip;
    /* position: relative; */
}

.content {
    margin-top: 100px;
}

.sidebar-container {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 !important;
    z-index: 10;
}
.profile-page {
    background-color: #F4F7FE;
    height: 100vh;
    overflow-y: scroll;
}

.content-container {
    padding: 0 !important;
    margin-left: 260px;
    position: relative;
    background-color: #F4F7FE !important;
    min-height: 155vh;
}
.content-container-profile {
    padding: 0 !important;
    margin-left: 133px;
    margin-top: 35px !important;
    position: relative;
    background-color: #F4F7FE !important;
}
.content {
    margin-top: 80px;
}

.loading {
    width: 100%;
    height: 98vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.loading h1 {
    color: black;
    font-family: "Dmsans400";
    font-size: 26px;
}
.loading-image {
    width: 180px;
    margin-top: 5px;
}
.profile-container {
    padding:60px 0px;
}

.App .Toastify__progress-bar--success {
    background: var(--main-color) !important;
}
.App .Toastify__toast-icon svg {
    fill: var(--main-color) !important;
}

input:focus-visible {
    outline: 1px solid #bec5c8; 
}

textarea:focus-visible {
    outline: 1px solid #bec5c8; 
}

.PhoneInputInput:focus-visible {
    outline: unset !important;
}
.disable-btn {
    pointer-events: none;
}
.disable-btn .add-btn-title {
    color: #bec5c8 !important ;
}
.disable-btn svg {
    fill: #bec5c8 !important
}
.disable-btn svg path {
    fill: #bec5c8 !important
}

@media (max-width: 1440px) {
    .content-container {
        margin-left: 225px !important;
    }
}

@media (max-width: 1200px) {
    .content {
        padding: unset !important;
    }
    .content-container {
        margin-left: unset !important;
    }
    .sidebar-container {
        display: none;
        /* position: fixed;
        height: 100%;
        left: 0;
        top: 0;
        width: 250px !important;
        padding: 0 !important;
        z-index: 48; */
    }
}

@media (max-width: 828px) {
    .content-container {
        margin-left: 125px;
    }
}

@media (max-width: 776px) {
    .sidebar-container {
        display: none;
    }
    .content-container {
        margin-left: unset;
    }

}

@media (max-width: 576px) {
    .content-container {
        background-color: #fff !important;
    }
    .content {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }
}






/* Loading Star */
.star {
	margin: auto;
	width: 9em;
	height: 9em;
}
.star__stroke {
	--dur: 4s;
	animation: stroke1 var(--dur) linear infinite;
}
.star__stroke--2 {
	animation-name: stroke2;
	animation-delay: calc(var(--dur) / -2);
}
.star__stroke--3 {
	animation-name: stroke3;
	animation-direction: reverse;
}
.star__stroke--4 {
	animation-name: stroke4;
	animation-delay: calc(var(--dur) / -2);
	animation-direction: reverse;
}

@keyframes stroke1 {
	from { stroke-dashoffset: 0; }
	to { stroke-dashoffset: 124; }
}
@keyframes stroke2 {
	from { stroke-dashoffset: 0; }
	to { stroke-dashoffset: 248; }
}
@keyframes stroke3 {
	from { stroke-dashoffset: 0; }
	to { stroke-dashoffset: 372; }
}
@keyframes stroke4 {
	from { stroke-dashoffset: 0; }
	to { stroke-dashoffset: 496; }
}


/* subPopup Style */
.subpopup-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    background: #000;
    color: #fff;
    padding: 20px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
}

.subpopup-text {
    color: #fff;
    font-family: "Poppins300";
    text-align: center;
    text-transform: capitalize;
}

.subpopup-link {
    margin-top: 15px;
    color: #000;
    background: #fff;
    padding: 5px 16px;
    font-size: 15px;
    border-radius: 5px;
    text-align: center;
    font-family: "Poppins400";
}