.modal-document {
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-radius: 24px;
    background: rgba(45, 176, 216, 0.11);
    color: var(--main-color);
    font-family: "Inter400";
    font-size: 12px;
    text-transform: capitalize;
}
.modal-document.active {
    background: var(--main-color);
    color: #fff;
}