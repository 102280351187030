.notes-content {
    border-top: 1px solid #EFEFEF;
    padding-top: 25px;
    margin-top: 25px;
    position: relative;
}

.notes-content:first-child {
    border-top: unset !important;
}

.notes-btn {
    position: absolute;
    right: 0;
    top: 25px;
    z-index: 1;
}

.notes-content .note-content {
    border-bottom: unset !important;
    padding-bottom: unset !important;
}
.notes-content .note-content p {
    margin: 0 !important;
}

@media (max-width: 576px) {
    .notes-content .note-title {
        margin-top: 10px !important;
    }
    .notes-content:first-child {
        margin-top: 0px !important;
    }
    .notes-content .note-content {
        border-bottom: unset !important;
        padding-bottom: unset !important;
        padding-top: 0px !important;

    }
    
}

