.hero-img {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7)) ,url('../../Assets/images/About/pexels-colin-lloyd.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 140px;
    padding-bottom: 40px;
}
.about-div {
    
}

.about-link {
    margin-bottom: 12px;
    color: #FFF;
    font-family: "Poppins400";
    font-size: 15px;
    text-transform: uppercase;
}
.about-link span {
    font-family: "Poppins200";
}

.about-title {
    color: #FFF;
    font-family: "Poppins400";
    font-size: 27px;
    letter-spacing: -0.6px;
    text-transform: uppercase;
}

/* .hero-img-overlay {
    height: 100%;
    opacity: 1;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    position: absolute;
} */
@media (max-width: 992px) {
    .hero-img {
        padding-top:134px;
    }

}

@media (max-width: 768px) {
    .hero-img {
        padding-top:125px;
        padding-bottom: 26px;
    }

}
