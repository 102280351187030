.hr-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.hr-components-container {
    position: relative;
    margin-top: 120px !important;
    width: 100% !important;
    margin-right: unset !important;
    margin-left: unset !important;
    justify-content: center !important;
}

#arrow-right, #arrow-left {
    position: absolute;
    width: 220px;
    bottom: calc(100% + 20px);
}
#arrow-left {
    left: 15%;
}
#arrow-right {
    right: 15%;
}
#arrow-left-down, #arrow-right-down {
    position: absolute;
    bottom: 130px;
    width: 190px;
}
#arrow-left-down {
    left: 10%;
}
#arrow-right-down {
    right: 10%;
}

.hr-title {
    color: #292929;
    text-align: center;
    font-family: "Poppins400";
    font-size: 40px;
    line-height: 58px;
    text-transform: capitalize;
    max-width: 55%;
}
.hr-title span {
    color: var(--main-color);
    font-family: "Poppins500";
    font-size: 75px;
}

.hr-para {
    margin-top: 20px;
    max-width: 75%;
    color: #313131;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.62px;
}

.hr-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.hr-links-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hr-links-responsive {
    display: none;
}
.right-star-div {
    position: absolute;
    top: 56px;
    right: 0px;
}
.right-star-img {
    width:212px;
}

@media (max-width: 1400px) {
    .hr-title span {
        font-size: 60px;
    }
    .hr-title {
        font-size: 36px;
    }
    .hr-container  {
        margin-top: 80px;
    }
    .hr-para {
        margin-top: 10px;
    }
    #arrow-right, #arrow-left {
        width: 200px;
    }
    #arrow-left-down, #arrow-right-down {
        width: 170px;
    }
}

@media (max-width: 1200px) {
    #arrow-left-down, #arrow-right-down {
        width: 160px;
        bottom: 110px;
    }
}

@media (max-width: 992px) {
    .hr-title {
        font-size: 35px;
        line-height: 50px;
        max-width: unset;
    }
    .hr-title span {
        font-size: 54px;
    }
    .hr-para {
        max-width: 92%;
        font-size: 15px;
    }
    #arrow-left-down, #arrow-right-down, #arrow-right, #arrow-left {
        display: none;
    }
    .hr-components-container {
        margin-top: 20px !important;
    }
    .hr-links {
        display: none;
    }
    .hr-links-responsive {
        margin-top: 40px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }
    .right-star-div {
        display: none;
    }
}

@media (max-width: 768px) {
    .hr-title {
        font-size: 30px;
        line-height: 45px;
    }
    .hr-title span {
        font-size: 45px;
    }
    .hr-para {
        max-width: 100%;
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .hr-title {
        font-size: 26px;
        line-height: 38px;
    }
    .hr-title span {
        font-size: 38px;
    }
    .hr-para {
        max-width: 100%;
        font-size: 13px;
        line-height: 28px;
        margin-top: 5px;
    }
}

@media (max-width: 476px) {
    .hr-title {
        font-size: 24px;
        line-height: 38px;
    }
    .hr-para {
        max-width: 100%;
        font-size: 12px;
        line-height: 26px;
    }
    .hr-components-container {
        margin-top: unset !important;
    }
}