.dropdown-item {
    text-transform: none !important;
}
.dropdown-item:active {
    background: #e9e9e9 !important;
    color: #212529 !important;
}
.dashboard-navbar {
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    margin-left: 247px !important;
    height: 75px !important;
    padding: 0 !important;
    z-index: 50 !important;
    background: #fff !important;
    padding: 20px !important;
    box-shadow: 24px 4px 24px 0px rgba(0, 0, 0, 0.07) 
}
.profile-sidebar {
    margin-left:unset !important;
}

.nav-search {
    width: 400px;
    position: relative;
}


.nav-search-input {
    width: 100%;
    background: #F3F3F3;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
}
.nav-search-input::placeholder {
    color: #1F384C;
    font-family: "Dmsans400";
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.5px;
    opacity: 0.3;
}
.nav-search-input:focus-visible {
    outline: 1px solid #6c757d8c !important;
}

.nav-search-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.profile-image {
    border-radius: 50%;
    width: 33px;
    height: 33px;
}
.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #eee;
}



.profile {
    display: flex;
    align-items: center;
    gap: 2px;
}

.profile-notification {
    width: 14px;
}

.navbar-nav  .profile-name {
    color: #1F384C;
    font-family: "Dmsans400";
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
}

.profile button {
    margin-left: 0 !important;
}
.profile .dropdown-toggle::after {
    display: inline-block;
    margin-left: 9px;
    vertical-align: 1px;
    content: "";
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-bottom: 0;
    opacity: 0.5;
    border-left: 6px solid transparent;
} 

#profile-notification-badge {
    background: #EC5252 !important;
    width: 13px !important;
    height: 13px !important;
    border-radius: 50% !important;
    font-size: 6px !important;
    margin-left: 6px !important;
    margin-top: -26px !important;
    display: table-caption !important;
    position: relative !important;
    line-height: unset !important;
}

.nav-links-pages {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 30px;
}

.nav-links-pages .custome-nav-link::after {
    position: absolute;
    content: '';
    width: 1.5px;
    height: 60%;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #1B2559;
}

.nav-links-pages a:last-child .custome-nav-link::after {
    display: none !important;
}

.nav-pages-responsive {
    display: none;
}

.nav-pages-responsive a {
    width: 100%;
}

.show-side-bar {
    display: none;
}

#basic-nav-dropdown {
    text-transform: capitalize !important;
}

.profile-dropdown-link {
    color: inherit; 
    text-decoration: none; 
}



.avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.show-back-bar{
    display: none;
    transform: rotateY(180deg);
}

.no-notification-container {
    width: 95%;
    margin: 0 auto;
    min-height: 300px;
    display: flex;
    align-items: center;
    height: 100%;
}

.no-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-notification-title {
    margin-top: 20px;
    color: #3E3E3E;
    font-family: "Poppins600";
    font-size: 14px;
    text-align: center;
}

.no-notification-para {
    color: #898989;
    text-align: center;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 20px; 
}


.language-switcher {
    margin: 0px 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .language-switcher label {
    font-size: 15px;
    font-weight: bold;
  }
  
  .language-switcher select {
    /* padding: 5px; */
    font-size: 15px;
    border: none;
    background-color: transparent;
    color: var(--main-color);
    /* border-radius: 5px;
    border: 1px solid #ccc; */
  }
  
  .language-switcher select:focus {
    outline: none;
    border-color: #007bff;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }


@media (max-width: 1440px) {
    .dashboard-navbar {
        margin-left: 235px !important;
    }
    .profile-page  .dashboard-navbar {
        margin-left:unset !important;
    }
}
@media (max-width: 1300px) {
    .custome-nav-link-title {
        font-size: 12px !important;
    }
}
@media (max-width: 1200px) {
    .dashboard-navbar {
        padding: 20px 0 !important;
        margin-left: unset !important;
    }
    .custome-nav-link-title {
        font-size: 12px !important;
    }
    .nav-pages-responsive {
        top: 0;
        display: flex;
        position: absolute;
        height: 200vh;
        width: 280px;
        background: #fff;
        z-index: 100;
        justify-content: start;
        align-items: start;
        flex-direction: column;
        transition: .3s ease;
    }
    .show-side-bar {
        display: block;
        width: 25px;
        height: 20px;
        background-color: transparent;
        background-image: url("../../assets/images/svg/sideBarBtn.svg");
        background-repeat: no-repeat;
        border: none;
        outline: none;
    }
    /* .side-bar-logo {
        margin: 10px 0 0 35px;
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 20px;
    } */
    .dashboard-navbar .navbar-nav {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: row !important;
        gap: 12px !important;
    }
    .nav-links-pages {
        gap:22px;
    }
    .nav-links-pages .custome-nav-link::after {
        right: -13px;
    }
    .show-back-bar {
        display: block;
        width: 25px;
        height: 20px;
        background-color: transparent;
        background-image: url("../../assets/images/svg/arrowRight.svg");
        background-repeat: no-repeat;
        border: none;
        outline: none;
        margin-right: 30px;
    }
    .side-bar-logo {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 10px 0 0 15px;
        gap: 0px;
        transition: .3s ease;
    }
}

@media (max-width: 992px) {
    .profile {
        flex-direction: row !important;
        gap: 6px;
    }
    .profile .dropdown-toggle::after {
       margin-left: 5px;
    }
    .nav-search {
        width: 320px;
    }
    .custome-nav-link-title {
        font-size: 12px !important;
    }
    .profile-image {
        width: 28px;
        height: 28px;
        margin-top: -4px;
    }
    .navbar-nav .profile-name {
        display: none;
    }
    .nav-links-pages .custome-nav-link::after {
        right: -15px;
    }
}

/* @media (min-width: 992px) {
    .website-nav .container {
      max-width: 1140px !important;
    }
  }
   */


@media (max-width: 776px) {
    
    .nav-pages-responsive a {
        width: 100%;
    }

    .nav-search {
        width: 250px;
    }
   
    #profile-notification-badge {
        margin-left: 9px !important;
    }
    .profile-notification {
        width: 19px;
    }


}



@media (max-width: 576px) {

    .side-bar-logo:hover {
        transform: scale(1.06);
    }
    .nav-links-pages {
        display: none;
    }
    .dashboard-navbar {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 20px 5px !important;
    }
    .nav-search {
        width: 200px;
    }
    .nav-search-input {
        padding: 8px 20px;
        font-size: 14px;
    }
    .nav-search-input::placeholder {
        font-size: 14px;
    }
   
    .profile-image {
        width: 29px;
        height: 29px;
    }
 
    .profile-notification {
        width: 18px;
    }
    #profile-notification-badge {
        margin-left: 8px !important;
    }
    #side-bar-btn {
        margin: 10px 0 10px 20px;
    }
}

@media (max-width: 450px) {
.nav-search {
    width: 100%;
}
.nav-search-input {
    width: 90%;
}
/* .nav-search-icon {
    right: 37px;
} */
.dashboard-navbar .navbar-nav {
    width: 50%;
}
}

@media (min-width: 992px) {
    .website-nav .container, .website-nav .container-lg, .website-nav .container-md, .website-nav .container-sm {
        max-width: 1050px !important;
        padding-left: 5px  !important;
        padding-right: 5px  !important;
    }
  }
  @media (min-width: 1200px) {
    .website-nav .container, .website-nav .container-lg, .website-nav .container-md, .website-nav .container-sm, .website-nav .container-xl {
        max-width: 1140px !important;
        padding-left: 5px  !important;
        padding-right: 5px  !important;
    }
  }
  
  @media (min-width: 1400px) {
    .website-nav .container, .website-nav .container-lg, .website-nav .container-md, .website-nav .container-sm, .website-nav .container-xl, .website-nav .container-xxl {
        max-width: 1320px !important;
        padding-left: 5px  !important;
        padding-right: 5px  !important;
    }
  }
  

  @media (max-width: 1520px) {
    .navbar-nav .profile-name {
      display: none;
    }
  }