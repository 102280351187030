.contact-page {
    margin-bottom: 100px;
}

.contact-page .cotact-us-container {
    margin-top: 60px !important;
    padding-top: unset !important;
    background-image: unset !important;
    background-size: unset !important;
    background-repeat: unset !important;
    background-position: unset !important;
}

.contact-page .contact-us-img {
    top: 61%;
}