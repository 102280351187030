.add-employee-modal .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px 13.5px !important;
    border-radius: 5px !important;
    background: #FFF !important;
    font-family: "Poppins400" !important;
    font-size: 14px !important;
 
}

.add-employee-modal .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top: -10px !important;
}

.add-employee-modal .css-1wnsr1i {
    border: unset !important;
    border-radius: 4px;
    padding-bottom: 20px !important;
}

.add-employee-btns {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 3px;
}

.add-employee-save, .add-employee-cancle {
    font-family: "Poppins400" !important;
}

.add-employee-cancle {
    color: #828282 !important;
}
.add-employee-save {
    color: var(--Secondary-Dark-Grey-900, #1B2559) !important;
}

.add-employee-title {
    font-family: "Poppins400" !important;
    font-size: 16px !important;
}
.second-add-employee-title {
    margin-top: 15px !important;
    font-family: "Poppins400" !important;
    font-size: 14px !important;
}

.add-employee-modal .profile-photo-div-parent {
    margin: 20px 0 10px !important;
    justify-content: center !important;
}
.add-employee-modal .profile-photo-div {
    position: relative !important;
}
.add-employee-modal .c1 {

    position: absolute;
    left: 50px !important;
    bottom: 1px !important;
}