.form-switch {
    margin-top: 10px !important;
}

.form-check-label {
    color: #101113;
    font-family: "Dmsans500";
    font-size: 14px;
    margin-left: 14px;
}

.form-switch .form-check-input {
    background-color:  var(--Neutral-200, #E6E6E6) !important;
    width: 40px !important;
    height: 23px !important;
    padding: 2px !important;
    border-radius: 48px !important;
    background-image: url(../../../../assets/images/svg/switchbtn.png) !important;
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #2DB0D8 !important;
    border: 1px solid #2DB0D8 !important;
}

.dashboard-amendments .note-content { 
    width: 100% !important;
}

@media (max-width: 1200px) {
    .form-check-label {
        font-size: 12px !important;
        margin-left: 10px !important;
    }
}

@media (max-width: 576px) {
    .form-switch .form-check-input {
        width: 35px !important;
        height: 23px !important;
    }

    .amendments-content {
        padding-top: 30px !important;
        margin-top: 10px !important;
    }
}
