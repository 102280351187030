.detail .rpv-core__page-layer::after {
  box-shadow: none !important;
}
.detail:not(:last-child) {
    margin-bottom: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #D7D7D7;
}

.detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom:20px;
}
.detail-side {
    position: absolute;
    width: 40px;
    height: 125px;
    right: -96px;
    top: 0;
    background: var(--Gray-Gray-0, #FCFCFF);
    border-radius: 20px;
    border: 1px solid #E7E7EF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.detail-side-btn {
    background: none;
    border: none;
    outline: none;
}

.detail-header-content {
    margin-top: 25px;
    position: relative;
    width: 100%;
    padding-left: 20px;
}
.detail-header-content::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 3px;
    background: url("../../../../assets/images/svg/locationblack.svg") no-repeat;
    width: 12px;
    height: 16px;
}


.detail-header-title {
    color: #1B2559;
    font-family: "Inter600";
    font-size: 18px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
}

.detail-header-para {
    display: flex;
    color: var(--Secondary-Grey-600, #5D5D5D);
    font-family: "Dmsans500";
    font-size: 11px;
    letter-spacing: -0.24px;
}

.deail-header-version {
    margin-left: 14px;
    position: relative;
}
.deail-header-version span {
    color: #1B2559;
    font-family: "Dmsans700";
    letter-spacing: 1.2px;
}
.deail-header-version::before {
    content: "";
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
    height: 64%;
    width: 1px;
    background: #D7D7D7;
}

.detail-btns {
    display: flex;
    align-items: center;
    gap: 20px;
    min-width: 63%;
}
.detail-btn {
    position: relative;
}
.detail-btn:not(:last-child)::after {
    content: "";
    position: absolute;
    right: -10px;
    top: 50%;
    background: #D7D7D7;
    width: 1px;
    height: 64%;
    transform: translateY(-50%);
}

.detail-title {
    margin-top: 40px;
    color: #000;
    font-family: "Inter700";
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
}

.detail-para {
    color: #000;
    text-align: justify;
    font-family: "Inter500";
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
}

.regulations-details-parent {
  font-family: "Dmsans300" !important;
  line-height: 1.6;
  font-size: 14px;
  color: #333;
  background-image: unset !important;
}





.regulations-details-parent h1, .regulations-details-parent h2, .regulations-details-parent h3, .regulations-details-parent h4, .regulations-details-parent h5, .regulations-details-parent h6 {
  font-weight: bold;
  color: #222;
  margin-bottom: 0.5em;
  
}
.regulations-details-parent h5, .regulations-details-parent h6 {
  font-size: 14px;
}

.regulations-details-parent p {
  margin-bottom: 1em;
}

/* Header Styles */

/* .regulations-details-parent header {
  background-color: #f8f8f8;
  color: #222;
  padding: 1em;
  text-align: center;
} */

.regulations-details-parent header h1{
  font-size: 24px;
  margin-bottom: 0;
}

/* Navigation Styles */

.regulations-details-parent nav {
  background-color: #444;
  padding: 1em;
  text-align: center;
}
/* Main Content Styles */

.regulations-details-parent main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
}

/* .regulations-details-parent section {
  background-color: #fff;
  padding: 1em;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */

/* Footer Styles */

.regulations-details-parent footer {
  background-color: #333;
  color: #fff;
  padding: 1em;
  text-align: center;
  clear: both;
}

.regulations-details-parent footer p {
  margin-bottom: 0;
}

/*custom*/
.regulations-details-parent .HTitleText2 {
  color: #007497;
  font-family: "Dmsans500" !important;

}

.regulations-details-parent .Title-of-Act{
  margin-top: 0;
}

.regulations-details-parent .ReaderNote {
  width: 100% !important;
}

.regulations-details-parent .ReaderNote p  {
  margin: 0;
}

.regulations-details-parent span.HLabel1 {
  font-size: 14pt;
}

.regulations-details-parent span.HTitleText1 {
  font-size: 23pt;
}

/* .regulations-details-parent .Part {
  margin-bottom: 1.5em;
} */

.regulations-details-parent a {
  /* color:#2db0d8 !important; */
}

.regulations-details-parent span.HTitleText2 {
  font-size: 18pt;
  margin-top: 20px;
}

.regulations-details-parent .MarginalNote {
  font-size: 14pt;
}

.regulations-details-parent .table-striped > tbody > tr:nth-child(odd) {
    background-color: #fcfcfc !important;
}

@media (max-width: 1400px) {
    .add-btn-title, .add-btn-count {
        font-size: 12px;
    }
}

@media (max-width: 1200px) {
    .detail-btns {
        display: flex;
        align-items: start;
        gap: 15px;
        background: #fff;
        padding: 20px;
        position: absolute;
        flex-direction: column;
        right: 10px;
        top: 45px;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14); 
        z-index: 10;
    }
    .detail-btn::after {
        display: none;
    }
    .detail-header .show-btns {
        display: block;
        top: 30px;
    }
    .regulation-container {
        width: 100%;
    }
}

@media (max-width: 1100px) {
    .detail-side {
        padding: 4px 15px;
        width: unset; 
        height: unset;
        right: 24px;
        top: 21px;
        flex-direction: row;
        gap: 15px;
        z-index: 10;
    }

    .detail-side .NotePopUp, .detail-side .ReminderPopUp {
        bottom: unset !important;
    }
}

@media (max-width: 992px) {
    .regulation-title {
        font-size: 25px;
    }
    .detail-side .NotePopUp, .detail-side .ReminderPopUp {
        right: 4px;
    }
}

@media (max-width: 768px) {
    .regulation-title {
        font-size: 18px;
    }
    .detail-side-btn img {
        width: 15px;
        height: 15px;
    }
    .detail-side {
        padding: 2px 10px 4px;
        gap: 10px;
    }
    .detail-header-title {
        font-size: 16px;
    }
    .detail-header-content::before {
        left: 1px;
        top: 2px;
        width: 12px;
        background-size: contain;
        height: 14px;
    }
    .detail-para {
        font-size: 12px;
    }
}

@media (max-width: 476px ) {
    .detail-para {
        font-size: 11px;
    }
    .detail-side {
        padding: 0px 9px 2px;
        gap: 7px;
    }
    .detail-side-btn img {
        width: 13px;
        height: 13px;
    }
    .detail-side {
        top: 24px;
    }
}




/* 
.regulations-details h1, .regulations-details h2,.regulations-details h3, .regulations-details h4,.regulations-details  h5, .regulations-details h6 {
    font-weight: bold;
    color: #222;
    margin-bottom: 0.5em;
    
  }
  .regulations-details h5,.regulations-details h6 {
    font-size: 14px;
  }
  
  .regulations-details p {
    margin-bottom: 1em;
  } */
  
  /* Header Styles */
/*   
  .regulations-details header {
    background-color: #f8f8f8;
    color: #222;
    padding: 1em;
    text-align: center;
  }
  
  .regulations-details header h1{
    font-size: 24px;
    margin-bottom: 0;
  }
   */
  /* Navigation Styles */
/*   
  .regulations-details nav {
    background-color: #444;
    padding: 1em;
    text-align: center;
  } */
  /* Main Content Styles */
  
  /* .regulations-details main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
  }
  
  .regulations-details section {
    background-color: #fff;
    padding: 1em;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  } */
  
  /* Footer Styles */
  
  /* .regulations-details footer {
    background-color: #333;
    color: #fff;
    padding: 1em;
    text-align: center;
    clear: both;
  }
  
  .regulations-details footer p {
    margin-bottom: 0;
  } */
  
  /*custom*/
  /* .regulations-details .HTitleText2 {
    color: #007497;
    font-family: "Dmsans500" !important;
  
  }
  
  .regulations-details .Title-of-Act{
    margin-top: 0;
  }
  
  .regulations-details .ReaderNote {
    width: 100% !important;
  }
  
  .regulations-details .ReaderNote p  {
    margin: 0;
  }
  
  .regulations-details span.HLabel1 {
    font-size: 14pt;
  }
  
  .regulations-details span.HTitleText1 {
    font-size: 23pt;
  }
  
  .regulations-details .Part {
    margin-bottom: 1.5em;
  }
  
  .regulations-details span.HTitleText2 {
    font-size: 18pt;
    margin-top: 20px;
  }
  
  .regulations-details .MarginalNote {
    font-size: 14pt;
  } */


  .regulations-details iframe {
    width: 100%;
  }