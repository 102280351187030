/* .note-content:not(:first-child) {
    margin-top: 15px !important;
} */
.note-content {
    border-bottom: 1px solid hsl(0, 0%, 94%);
    padding-bottom: 20px;
    /* padding-top: 20px; */
}
.note-content {
    width: 50%;
    padding-top: 20px;
}
  
.note-container .note-content:last-child {
    border-bottom: none !important;
}
.last-note{
    border-bottom: unset !important;
}

.note-info {
    position: relative;
    margin-left: 15px;
}
.note-info::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 95%;
    top: 50%;
    left: -12px;
    border-radius: 1px;
    transform: translateY(-50%);
    background: var(--main-color);
}

.note-date {
    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Dmsans700";
    font-size: 15px;
    font-style: normal;
    letter-spacing: -0.32px;
    margin-bottom: 0;
}

.note-btn {
    border: none;
    background: none;
    color: #2DB0D8;
    font-family: "Dmsans500";
    font-size: 12px;
    display: flex;
    margin-top: 5px;
}
.note-btn-icon {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}

.note-title {
    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Dmsans700";
    font-size: 14px;
    letter-spacing: -0.28px;
    margin: 0;
    margin-bottom: 0px;
}
.note-title:first-child {
    text-decoration: underline;
}

.note-para {
    color: #1B2559;
    font-family: "Dmsans400";
    font-size: 13px;
    line-height: 26px;
    letter-spacing: -0.28px;
}
.note-para span {
    font-family: "Dmsans700" !important;
}
.note-para a {
    color: #2DB0D8 !important;
    font-family: "Dmsans700" !important;
    font-size: 12px !important;
    line-height: 28px; 
    letter-spacing: -0.24px;
    text-decoration-line: underline !important;
    cursor: pointer !important;
}

.note-content-container {
    margin-top: -6px !important;
}

@media (max-width: 1450px) {
    .note-date {
        font-size: 14px !important;
    }
}

@media (max-width: 768px) {
    .note-content {
        display: flex;
        justify-content: space-between;
        align-items: start;
        padding-top:25px;
        /* flex-wrap: unset !important; */
        /* gap: 20px !important; */
        padding-top: unset !important;
    }
    .note-content-container {
        margin-top: var(--bs-gutter-y) !important;
        padding-left: 28px;
        padding-top: 8px;
    }
}
 
@media (max-width: 576px) {
    .note-title {
        font-size: 13px !important;
        margin-top: 22px !important;
    }
    .note-content:not(:first-child) {
        margin-top: 0px !important;
    }
}

@media (max-width: 476px) {
    .note-content {
        flex-wrap: wrap !important;
    }
}