.global-btn {
    border-radius: 12px !important;
    font-family: "Inter400" !important; 
    font-size: 13px !important;
    letter-spacing: 0.25px !important;
    border: none !important;
    padding: 5px 20px !important;
}

@media (max-width: 992px) {
    .global-btn {
        font-size: 11px !important;
    }
}