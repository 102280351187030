.home-hero {
    width: 100%;
    margin-top: 200px;
}

.hero-title {
    color: #292929;
    font-family: "Poppins300";
    font-size: 43px;
    text-transform: uppercase;
}
.hero-title span {
    color: var(--main-color);
    font-size: 60px;
    font-family: "Poppins600";
    margin: 0 10px;
}
.hero-title span:first-child {
    font-size: 48px !important;
    color: #000 !important;
    margin: 0 !important;
    font-family: "Poppins300" !important;
}

.hero-second-title {
    color: #000;
    font-family: "Poppins200";
    font-size: 23px;
    text-transform: capitalize;
    letter-spacing: 0.65px;
    margin-top: 5px;
}
.hero-second-title span {
    font-family: "Poppins400";
    text-transform: uppercase;
}

.hero-para {
    margin: 27px 0;
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 30px; 
    letter-spacing: 0.6px;
    max-width: 77%;
}

.hero-link {
    color: #292929;
    font-family: "Poppins300";
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 12px;
    text-transform: uppercase;
    position: relative;
    margin-left: 40px;
    cursor: pointer;
}
.hero-link::after {
    position: absolute;
    background: var(--main-color);
    content: "";
    left: -40px;
    width: 60%;
    height: 54%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    transition: .3s ease;
}

.hero-link:hover::after {
    width: calc(100% + 63px);
}

.hero-image  {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    width: 120%;
    object-fit: contain;
}
.left-star-div {
    position: absolute;
    top:293px;
    left:0px;
}
.left-star-img {
    width:212px;
}

@media (max-width: 1400px) {
    .hero-image img {
        width: 110%;
    }
    .hero-title {
        font-size: 44px;
    }
    .hero-title span:first-child  {
        font-size: 46px !important;
    }
    .hero-second-title {
        font-size: 22px;
    }
    .hero-para {
        margin: 20px 0;
        max-width: 86%;
    }
    .hero-link {
        font-size: 26px;
    }
    .home-hero {
        margin-top: 190px;
    }
}

@media (max-width: 1200px) {
    .hero-image img {
        width: 140%;
    }
    .hero-title {
        font-size: 42px;
    }
    .hero-title span:first-child  {
        font-size: 44px !important;
    }
    .hero-second-title {
        font-size: 21px;
    }
    .hero-para {
        font-size: 15px;
        margin: 19px 0;
        max-width: 84%;
    }
    .hero-link {
        font-size: 24px;
    }
    .home-hero {
        margin-top: 180px;
    }
}

@media (max-width: 992px) {
    .home-hero {
        margin-top: 110px;
    }
    .hero-image img {
        width: 85%;
    }
    .hero-content { 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .hero-image {
        margin-top: 40px;
    }
    .hero-para {
        text-align: center;
        margin: 16px 0;
    }
    .hero-link {
        margin-left: unset;
    }
    .left-star-div {
        display: none;
    }
    .hero-second-title {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .hero-title {
        font-size: 36px;
    }
    .hero-title span:first-child {
        font-size: 38px !important;
    }
    .hero-title span {
        font-size: 56px;
    }
    .hero-second-title {
        font-size: 18px;
        letter-spacing: 1px;
    }
    .hero-para {
        font-size: 14px;
        margin: 11px 0;
    }
    .hero-link {
        font-size: 21px;
    }
}

@media (max-width: 476px) {
    .hero-title span:first-child {
        font-size: 30px !important;
    }
    .hero-title {
        font-size: 28px;
    }
    .hero-title span {
        font-size: 40px;
    }
    .hero-second-title {
        font-size: 16px;
    }
    .hero-para {
        font-size: 13px;
        margin: 9px 0;
        max-width: unset;
        line-height: 28px;
    }
    .hero-link {
        font-size: 18px;
        letter-spacing: 5px;
    }

}

@media (max-width: 400px) {
    .hero-second-title {
        font-size: 15px;
    }
    .hero-para {
        line-height: 25px;
    }
}