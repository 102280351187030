
.sidebar {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    height: 100%;
    background: #fff;
}

.sidebar-logo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.css-ewdv3l {
    margin-top: 5px !important;
}

.css-dip3t8 {
    background-color: #fff !important;
}

.css-1wvake5 {
    border: none !important;
}

.filterSidebar {
    width: 80%;
    margin: 6px auto 0;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
} 

.logo-filter-sidebar {

}
.filter-sidebar-search {
    padding: 5px 10px;
    border: 1px solid #E6E6E6;
    margin-top: 10px;
    width: 100%;
}
.filter-sidebar-search::placeholder {
    font-size: 13px;
    color: #E6E6E6;
    font-family: "Dmsans300";
    letter-spacing: 1.26px;
    text-transform: capitalize;
}

.filter-title {
    color: #1B2559;
    font-family: "Dmsans400";
    font-size: 13px;
    font-style: normal;
    letter-spacing: 1.26px;
    text-transform: uppercase;
}
.filterSidebar .filter-title:not(:first-child) {
    margin-top: 24px;
    margin-bottom: 0 !important;
}

.css-dip3t8 {
    padding-top: 10px;
}


.apply-btn {
    width: 100%;
    margin: 0;
    padding: 6px 7px;
    border-radius: 10px;
    background: var(--main-color);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    color: #fff;
    border: none;
    outline: none;
    text-align: center;
    margin-top: 40px !important;
    font-family: "Dmsans400";
    font-size: 13px;
    font-style: normal;
    letter-spacing: 1.26px;
    text-transform: uppercase;
}

.custom-skeleton {
    border-radius: 0px !important;
  }

.ps-menu-button span {
    display: flex;
}

@media (max-width: 1440px) {
    .css-1wvake5 {
        min-width: 235px !important;
    }
}

@media (max-width: 1200px) {
    .css-dip3t8 {
       width: 100% !important;
    }
}

@media (max-width: 828px) {
    .css-dip3t8 {
        width: 110px !important;
    }
    .css-1t8x7v1 >.ps-menu-button {
        padding-right: unset !important;
        padding-left: unset !important;
    }
    .ps-menu-button {
        margin-top: 15px !important;
    }
    .ps-menu-button img {
        width: 95px !important;
    }
    .css-1wvake5 {
        width: unset !important;
        min-width: unset !important;
    }
}

@media (max-width: 776px) {
   
}

@media (max-width: 576px) {

}

