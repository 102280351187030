.report {
    position: relative;
    border-top: 1px solid #EFEFEF;
    padding-top: 25px;
    margin-top: 30px;
}

.report-property {
    color: var(--Secondary-Grey-600, #5D5D5D);
    font-family: "Dmsans500";
    font-size: 14px;
    letter-spacing: -0.28px;
    max-width: 92%;
}

.report-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report-title-link {
    max-width: 45%;
}
.report-title {
    /* margin: 5px 0 10px; */
    margin: 10px 0 10px;

    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Dmsans500";
    font-size: 17px;
    font-style: normal;
    letter-spacing: -0.4px;
    cursor: pointer;
    transition: .2s ease;
}
.report-title:hover {
    color: var(--main-color);
    text-decoration: underline;
}

.report-para {
    color: var(--Secondary-Grey-600, #5D5D5D);
    font-family: "Dmsans500";
    font-size: 14px;
    letter-spacing: -0.28px;
    text-align: justify;
    line-height: 20px;
}

.report-btns {
    display: flex;
    gap: 15px;
    position: relative;
    min-width: 37%;
    justify-content: end;
}

.NotePopUp {
    display: none;
    position: absolute;
    right: 30px;
    /* 
    This conflict when merge esraa & hosam
    right: 213px;
    bottom: calc(100% + 12px); */  
    

    right: 30px;
    bottom: calc(100% + -75px);
}
.ReminderPopUp {
    display: none;
    position: absolute;
    /* right: 78px;
    bottom: calc(100% + 12px); */
    right: 30px;
    bottom: calc(100% + -32px);
}
.NotePopUp.active {
    display: block;
    z-index: 50;

    /* z-index: 40; */
}
.ReminderPopUp.active {
    display: block;
    /* z-index: 40;   */
      z-index: 50;
}

.regulation-type {
    font-size: 13px;
    color: var(--main-color);
    font-family: "Poppins300";
}

@media (max-width: 992px) {
    .NotePopUp, .ReminderPopUp {
        right: 4px;
        bottom: 32px;
    }
    /* .report-btns {
        display: flex;
        align-items: start;
        gap: 15px;
        padding: 20px;
        position: absolute;
        flex-direction: column;
        right: 11px;
        top: 29px;
        z-index: 10;
    } */
    .report-title-link {
        max-width: 90%;
    }

    .report-btns {
        display: none;
    }
}

@media (max-width: 768px) {
    .report-title-container {
        flex-direction: column-reverse;
        gap: 15px;
        align-items: start;
    }
    .report-btns {
        gap: 10px;
    }
    .report-property {

        max-width: 90%;
    }
}

@media (max-width: 476px) {
    .NotePopUp,  .ReminderPopUp {
        right: 7px;
        bottom: 16px;
    }
    .report-btns {
        padding: 12px;
    }
    .report-dots .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
        min-height: 33px !important;
    }
}