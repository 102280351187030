.reminders-content {
    border-top: 1px solid #EFEFEF;
    padding-top: 25px;
    /* margin-top: 25px; */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.reminders-content .note-content {
    width: 54%;
}

.reminders-info {
    display: flex;
    align-items: center;
    gap: 25px;
}

.reminders-btn {
    position: relative;
}
.reminders-btn::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 95%;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
    background: #D7D7D7;
}

.reminders-skeleton {
    margin-top: 40px;
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.first-rminders-skeleton {
    display: flex;
    align-items: start;
    gap: 40px;
}

/* @media (max-width: 776px) {
    .reminders-content .note-content {
        width: 100%;
    }
} */

@media (max-width: 1300px) {
    .reminders-info {
        min-width: 38% !important;
        justify-content: end;
    }
}

@media (min-width: 1200px) {
    .first-rminders-skeleton div {
        width: 270px;
    }
    .second-rminders-skeleton {
        width: 180px;
    }
}

@media (max-width: 1200px) {
    .reminders-info {
        min-width: 41% !important;
    }
    .first-rminders-skeleton div {
        width: 220px;
    }
    .second-rminders-skeleton {
        width: 150px;
    }
}

@media (max-width: 992px) {
    .reminders-info {
        min-width: unset !important;
        justify-content: unset !important;
        flex-direction: column;
        align-items: flex-start;
    }
    .reminders-btn::after {
        display: none;
    }
    .first-rminders-skeleton div {
        width: 180px;
    }
    .second-rminders-skeleton {
        width: 120px;
    }
    .reminders-content .note-content {
        width: 80%;
    }    
    
}

@media (max-width: 768px) {
    .reminders-info {
        flex-direction: row;
        padding-left: 15px;
        padding-top: 8px;
        gap: 25px;
        align-items: flex-start;
    }
    .reminders-btn::after {
        display: block;
    }
    .reminders-skeleton {
        flex-direction: column;
    }
    .first-rminders-skeleton {
        width: 100%;
        justify-content: space-between;
    }
    .first-rminders-skeleton div {
        width: 160px;
    }
    .second-rminders-skeleton {
        width: 200px;
    }
    .reminders-content .note-content {
        width: 100%;
    }   
}

@media (max-width: 576px) {
    .first-rminders-skeleton {
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
    .second-rminders-skeleton {
        margin-top: 20px;
    }

}