.features-container {
    margin-top: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.features-para{
    margin-top: 16px;
    max-width: 60%;
    color: #313131;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 30px; 
    letter-spacing: 0.6px;
}

.features-video {
    position: relative;
    margin-top: 40px;
    width: 100%;
    border: 22px solid #000;
    border-radius: 46px;
}
.features-video video {
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}
.features-video img {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: -1;
    width: 130px;
}
.features-video::after {
    position: absolute;
    content: "";
    border-radius: 29px;
    opacity: 0.2;
    background: var(--main-color);
    z-index: -1;
    left: 73%;
    top: 20%;
    width: 33%;
    height: 90%;
}

.features-link {
    margin-top: 180px;
}

@media (max-width: 1200px) {
    .features-para {
        font-size: 15px;
        line-height: 27px;
        margin-top: 15px;
        max-width: 75%;
    }
    .features-video {
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .features-para {
        max-width: 85%;
    }
    .features-video::after {
        left: 69%;
        top: 26%;
        width: 38%;
    }
    .features-video img {
        display: none;
    }
    .features-link {
        margin-top: 60px;
    }
}

@media (max-width: 768px) {
    .features-para {
        font-size: 14px;
        line-height: 26px;
        max-width: unset;
    }  
    .features-video {
        border: 13px solid #000;
    }
    .features-video img {
        width: 110px;
    }
    .features-link {
        margin-top: 60px;
    }
}


@media (max-width: 576px) {
    .features-container {
        margin-top: 120px;
    }
    .features-para {
        font-size: 13px;
        line-height: 25px;
    }
    .features-video {
        width: 85%;
    }
    .features-video img {
        width: 95px;
    }
    .features-video {
        border: 11px solid #000;
        border-radius: 24px;
    }
    .features-video::after {
        border-radius: 26px;
    }
}

@media (max-width: 476px) {
    .features-para {
        font-size: 12px;
        line-height: 25px;
    }
    .features-video img {
        width: 90px;
    }

    .features-video {
        width: 90%;
        border: 10px solid #000;
        border-radius: 20px;
    }
    .features-video::after {
        border-radius: 22px;
        left: 69%;
        top: 27%;
        width: 40%;
    }
}