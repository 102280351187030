.provinces-label {
    color: #303030;
    font-family: "Dmsans400";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; 
    letter-spacing: -0.28px;
    text-transform: capitalize;
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    padding-left: 32px;
    }
    
      .custom-checkbox {
        display:none;
      }
    .checkmark {
        position: absolute;
        top: -2px;
        left: 0;
        height: 18px;
        width: 18px;
        border: 1px solid #D2D2D2;
      }
      
      /* .provinces-label:hover input ~ .checkmark {
        background-color: #ccc;
      } */
      
      .provinces-label input:checked ~ .checkmark {
        background-color:  #2DB0D8;
      }
      
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      .provinces-label input:checked ~ .checkmark:after {
        display: block;
      }
      
      .provinces-label .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .PhoneInputInternationalIconGlobe {
        display: none;
      }
      
      
    
    
      
.button-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 30px !important;
}
.btn-user-details {
  width: unset !important;
}