.cart {
    border: 1px solid #CCCCCC;
    border-radius: 16px;
    color:#000;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 30px;
    height: 530px;
}

.cart-pro {
    background-color: #13161B;
    color:#fff;
    position: relative;
}

.btn-recomended {
    margin-bottom: 15px;
    max-width: 136px;
    font-family: "Poppins500";
    background-color: rgba(45, 176, 216, 0.2);
    color: #2DB0D8;
    font-size: 11px;
    border-radius: 90px;
    padding: 6px 26px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.plan-type {
    color:#2DB0D8;
    font-family: "Poppins600";
    font-size: 18px;
    margin-bottom:20px;
}
.cart-text {
    font-family: "Poppins400";
    font-size: 14px;
    margin-bottom: 20px;
}

.plan-price {
    font-size: 28px;
    /* margin-bottom: 10px; */
    font-family: 'Poppins400';
}

.btn-plan {
    color: #fff;
    background-color: #000;
    font-family: "Poppins400";
    width: 100%;
    padding: 10px 24px;
    font-size: 15px;
    border-radius:7px;
    border: none;
    transition: .3s ease;
}
.btn-plan:hover {
    transform: scale(1.04);
}

.btn-plan-pro {
    color:#000;
    background-color: #fff;
}

.feature {
    display: flex;
    gap: 5px;
}

.check-icon {
    width:20px;
    margin-bottom: 20px;
}

@media (max-width: 1400px) {
    
}


@media (max-width: 1200px) {

}


@media (max-width: 992px) {
    .btn-recomended {
        font-size: 9px;
        padding: 6px 10px;
        top: 22px;
        right: 8px;
    }
    .plan-type {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .plan-price {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .cart-text {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .check-icon {
        width: 17px;
        margin-bottom: 10px;
    }
    .cart {
        gap: 10px;
        padding:20px 10px;
    }
    .btn-plan{
        font-size: 14px;
    }

}

@media (max-width: 768px) {
    .btn-recomended {
        font-size: 13px;
        padding: 6px 20px;
        top: 20px;
        right: 20px;
    }

    .plan-type {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .plan-price {
        font-size: 32px;
        margin-bottom: 10px;
    }
    .cart-text {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .check-icon {
        width: 20px;
        margin-bottom: 20px;
    }
    .cart {
        gap: 30px;
        padding:20px ;
        margin: 20px auto 0;
        width: 80%;
    }
    .btn-plan{
        font-size: 18px;
    }

}

@media (max-width: 476px) {
    .cart-text {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .plan-price {
        font-size: 28px;
    }
    .btn-plan {
        font-size: 15px;
    }
    .cart {
        height: 520px;
    }
    .btn-recomended {
        font-size: 11px;
    }
}