.link-btn {
    display: inline-block;
    text-align: center;
    width: 245px;
    padding: 11px;
    font-family: "Poppins500";
    font-size: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    border: 1px solid var(--main-color);
}

@media (max-width: 1200px) {
    .link-btn {
        width: 215px;
        font-size: 13px;
    }
}

@media (max-width: 768px) {
    .link-btn {
        width: 180px;
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .link-btn {
        width: 160px;
        font-size: 11px;
    }
}