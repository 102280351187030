.payment-title {
    font-size:18px;
    color:#212121;
    font-family: "Poppins400";
    font-weight: 500;
    margin-bottom:15px;
}
.payment-text {
    color: #313131;
    font-family: "Poppins400";
    font-weight: 400;
    font-size:14px;
    margin-bottom: 20px;

}
.label-radio {
    accent-color: #101728;
  }
  .label-radio input {
    margin-right: 10px;
  }
  .payment-card {
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.1);
    color: #212121;
    font-size: 14px;
    font-family: "Inter400";
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .package-title {
    color:#212121;
    font-family: "Poppins400";
    font-weight:600;
    font-size:22px;
  }
  .plan-details {
    border-top: 1px dashed #D2D2D2;
    border-bottom: 1px dashed #D2D2D2;
    margin: 10px 0px;
    padding: 10px 0px;
  }
  .flex-div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .plan-text {
    color: #212121;
    font-size: 14px;
    font-family: "Inter400";
    font-weight: 400;

  }
  .btn-payment {
    background-color: #212121;
    color:#fff;
    width:100%;
    padding:10px 20px;
    border: none;
    border-radius: 5px;
    margin:20px 0px;
  }
  .package-price {
    color:#212121;
    font-weight: 700;
    font-size: 14px;
    font-family: "Inter400";
  }
  .total-price {
    color:#212121;
    font-weight: 700;
    font-size: 18px;
    font-family: "Inter400";
  }
  .discount{
    color:#43A047;
    margin-bottom: 10px;
  }
  .package-text {
    color: #818181;
    font-size: 12px;
    font-family: "Inter400";
    font-weight: 400;
    line-height: 22.5px;
  }
  .package-text a{
    color:#2DB0D8 !important;
  }


  .payment-container {
    position: relative;
  }
  .payment-container .ciruclar-spinner {
    color: var(--main-color);
  }
.payment-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-container-loading {
  opacity: 0.4;
}


@media (max-width: 768px) {
  .package-title {
    margin-top:40px;
  }
}

