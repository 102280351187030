.profile-tab {
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 20px;
    transition: 0.2s all ease-in-out;
    margin-bottom: 14px;
    width: 89%;
}
.profile-tab:hover .chevron {
    fill:#fff;
}
.profile-tab:hover .tab-title {
    color:#fff;
}
.profile-tab:hover .tab-text {
    color:#fff;
}
.circle-icon {
  position: relative;
  opacity: 2;
}
.profile-tab:hover svg .circle-icon {
    fill:#fff;
    opacity: 2;
}
.profile-tab:hover {
    background-color: #2DB0D8;
filter: drop-shadow(0px 4px 44px rgba(0, 0, 0, 0.06));
}


.profile-tab-flex {
    display: flex;
    gap: 20px;
    align-items: center;
}
.tab-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #181D27;
    padding-bottom: 5px;
}
.tab-text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #ABABAB;
}
.chevron-div {
    position: absolute;
    top: 40px;
    right: 18px;
}
.chevron {
    fill:#ABABAB;
}

@media (max-width: 992px) {
    .profile-tab {
        width: 100%;
    }
}