.lawyers {
    margin-top: 200PX;
}

.lawyers-container {
    display: flex;
    align-items: center;
}

.lawyers-image {
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
}

.lawyers-image::after {
    position: absolute;
    content: "";
    opacity: 0.2;
    background: var(--main-color);
    right: 0;
    top: -40px;
    width: 80%;
    height: 100%;
    z-index: -1;
}

.lawyers-para {
    margin-top: 20px;
    max-width: 92%;
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 30px; /* 194.444% */
    letter-spacing: 0.6px;
}

.lawyers-links {
    margin-top: 25px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
}


@media (max-width: 1400px) {
    .lawyers-image img {
        width: 90%;
    }
}

@media (max-width: 1200px) {
    .lawyers-para {
        font-size: 15px;
        line-height: 28px;
        margin-top: 14px;
    }
    
}

@media (max-width: 992px) {
    .lawyers {
        margin-top: 55px;
    }
    .lawyers-image::after {
        right: 10%;
        top: unset;
        bottom: -40px;
        width: 50%;
    }
    .lawyers-image {
        justify-content: center;
        margin-top: 80px;
    }
    .lawyers-image img {
        width: 65%;
    }
}

@media (max-width: 768px) {
    .lawyers-para {
        max-width: unset;
        font-size: 14px;
        line-height: 27px;
    }
    .lawyers-content h1 {
        line-height: 42px !important;
    }
}

@media (max-width: 576px) {
    .lawyers {
        margin-top: 25px;
    }
    .home-page .lawyers-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .lawyers-para {
        line-height: 26px;
        font-size: 13px;
    }
    .home-page .lawyers-para {
        text-align: center;
    }
    .lawyers-links {
        gap: 10px;
    }
}

@media (max-width: 476px) {
    .lawyers-para {
        font-size: 12px;
        line-height: 25px;
    }
    .lawyers-image::after {
        right: 5%;
        width: 65%;
        bottom: -32px;
    }
    .lawyers-image img {
        width: 75%;
    } 
}