.confirm-delete-container {
    width: 100%;
}

.delete-img {
    margin-bottom: 20px;
}
.confirm-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.confirm-delete {
    color: #fff;
    font-family: "Poppins400";
    font-size: 13px;
    background: #EC5252;
    padding: 10px 0px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    text-align: center;
    width: 50%;
    height: 100%;
}
.activate {
    background-color: var(--main-color);;
}
.deactivate {
    background: #EC5252;
}

.confirm-delete-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.cancle-delete {
    color: #000;
    font-family: "Poppins400";
    font-size: 13px;
    background: #F1F1F1;
    padding: 10px 0px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    text-align: center;
    width: 50%;
    height:100%;
}

.delete-title {
    margin: 0 !important;
    color: #000;
    font-family: "Poppins500";
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.215px;
    margin-bottom: 5px !important;
}

.delete-para {
    margin: 0 !important;
    color: rgba(84, 89, 94, 0.60);
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 20px; /* 157.143% */
    text-align: center;
}
