.reminders-content .note-content {
    border-bottom: none !important;
    padding-bottom: unset !important;
    padding-top: 0 !important;
}

.reminders-content:first-child {
    border-top: unset !important;
}   

.reminders-content {
    margin-top: 25px;
}
@media (max-width: 992px) {
    .reminders-content .note-btn {
        font-size: 11px;
    }
    .reminders-content .note-btn-icon {
        width: 13px;
        height: 13px;
    }
    .reminders-content {
        margin-bottom: 25px;
    }
}

@media (max-width: 768px) {
    .reminders-content {
        position: relative;
        flex-direction: column;
        gap: 10px;
    }
    /* .reminders-content .note-content {
        flex-wrap: unset !important;
    } */
    .reminders-content .note-btn {
        font-size: 13px;
    }
    .reminders-content .note-btn-icon {
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 576px) {
    .reminders-content {
        gap: 5px;
        padding-top:25px;
    }
    .reminders-content .note-content {
        flex-wrap: wrap !important;
    }
    .reminders-content:first-child {
        padding-top: 0px;
    }   
    .reminders-content .note-title {
        margin-top: 10px !important;
        
    }
}