.search {
    padding: 40px 30px;
}

.search .report:first-child {
    border-top: unset !important;
}

@media (max-width: 576px) {
    .search {
        padding: 40px 10px;
    }
}