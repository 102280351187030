.error-component {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 150px auto;
}

.error-component .star {
	margin: 24px auto 0;
	width: 20em;
	height: 20em;
}

.error-component .star__stroke {
	--dur: 15s;
	animation: stroke1 var(--dur) linear infinite;
}
.error-component .star__stroke--2 {
	animation-name: stroke2;
	animation-delay: calc(var(--dur) / -2);
}
.error-component .star__stroke--3 {
	animation-name: stroke3;
	animation-direction: reverse;
}
.error-component .star__stroke--4 {
	animation-name: stroke4;
	animation-delay: calc(var(--dur) / -2);
	animation-direction: reverse;
}

.error-component-title {
    color: #292929;
    text-align: center;
    font-family: "Poppins400";
    font-size: 48px;
    text-transform: uppercase;
}

.error-component-desc {
    margin-top: 8px;
    color: #878787;
    font-family: "Poppins300";
    font-size: 20px;
    text-transform: capitalize;
}

.error-component-para {
    margin-top: 12px;
    color: #7B7B7B;
    text-align: center;
    font-family: "Poppins300";
    font-size: 14px;
    line-height: 23px;
    text-transform: capitalize;
    max-width: 36%;
}