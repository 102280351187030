.reports-search {
    background: #fff;
    width: 400px;
    border: none;
    outline: none;
    padding: 13px 0px 13px 15px;
    border-radius: 5px;
    font-family: "Poppins500";
    font-size: 13px;
    letter-spacing: -0.2px;
}
.reports-search::placeholder {
    color: rgba(204, 204, 204, 0.70);
}

.reports-search-container {
    position: relative;
}

.reports-search-close, .reports-search-img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.reports-search-close {
    background: none;
    border: none;
    outline: none;
}
.add-report-btn {
    margin: 0;
    background: var(--main-color);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    color: #fff;
    border: none;
    outline: none;
    text-align: center;
    margin-top: 40px !important;
    font-size: 13px;
    font-family: "Dmsans500";
    border-radius: 5px;
    padding: 10px 20px;
}

.setting-report{
    display: flex;
    justify-content: center;
    align-items: end;
}

@media (max-width: 991px) {
    .add-report-btn{
        width: 100%;
    }
}

@media (max-width: 576px) {

}