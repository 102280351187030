.check-label {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.check-label-input {
    border: 1px solid #D2D2D2;
    width: 17px;
    height: 17px;
    /* background: transparent; */
}
.check-label-input:focus-visible {
    outline: none;
    outline-offset: unset;
}

.check-label label {
    color: #303030;
    font-family: "Dmsans400";
    font-size: 12px;
    letter-spacing: -0.28px;
    text-transform: capitalize;
}