.alert {
    margin-top: 10px;
    border-radius: 5px;
    padding: 15px 15px 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alert-content {
    display: flex;
    gap: 10px;
    align-items: center;
}

.alert-link {
    text-align: justify;
    font-family: "Inter200";
    font-size: 13px;
    letter-spacing: 0.25px;
    text-decoration: underline !important;
}

.alert-para {
    font-family: "Inter400";
    font-size: 13px;
    letter-spacing: 0.25px;
    margin: 0;
}

@media (max-width: 768px) {
    .alert-para {
        font-size: 11px;
    }
    .alert {
        padding-top: 13px !important;
        padding-bottom: 13px !important;
    }
    .alert-link {
        font-size: 11px;
    }
    .alert-content img {
        width: 13px;
    }
}

@media (max-width: 476px) {
    .alert-para {
        font-size: 10px;
    }
    .alert {
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
    .alert-link {
        margin-left: 22px;
        font-size: 9px;
    }
    .alert-content {
        gap: 9px;
    }
    .alert-content img {
        width: 11px;
    } 
}