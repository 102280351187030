.regulation-note-content {
    display: flex;
    justify-content: space-between;
    position: relative;
    /* width: 100%; */
}
.menudots-regulations-sm {
display: flex;
align-items: center;
}

@media (max-width: 768px){
.menudots-regulations-sm {
    position: absolute;
    top: -61px;
    right: 0px;
}
}


@media (max-width: 576px) {
    /* .note-content .MuiButtonBase-root {
        margin-top: 8px !important;
    } */
}
