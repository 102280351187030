.about-team {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-team-title {
    color: #292929;
    text-align: center;
    font-family: "Poppins400";
    font-size: 36px;;
    line-height: 55px;
    text-transform: capitalize;
}

.about-team-para {
    margin-top: 10px;
    color: #313131;
    max-width: 60%;
    text-align: center;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 24px; 
    letter-spacing: 0.54px;
}

.about-team .contact-us-img {
    top: 69% !important;
}

@media (max-width: 1200px) {
    .about-team-para  {
        max-width: 75%;
    }
}

@media (max-width: 768px) {
    .about-team-title  {
        font-size: 34px;
    }
    .about-team-para {
        max-width: unset;
        font-size: 13px;
        margin-top: 6px;
    }
}

@media (max-width: 576px) {
    .about-team-title  {
        font-size: 33px;
    }
}

@media (max-width: 476px) {
    .about-team-title  {
        font-size: 32px;
    }
    .about-team-para {
        font-size: 12px;
        line-height: 22px; 
    }
}