.confirm-add-container {
    width: 100%;
}

.add-img {
    margin-bottom: 20px;
}
.confirm-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.confirm-add {
    color: #fff;
    font-family: "Poppins400";
    font-size: 13px;
    background: var(--main-color);
    padding: 10px 0px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    text-align: center;
    width: 50%;
    height: 100%;
}

.cancle-add {
    color: #000;
    font-family: "Poppins400";
    font-size: 13px;
    background: #F1F1F1;
    padding: 10px 0px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    text-align: center;
    width: 50%;
    height: 100%;
}


.confirm-add-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}


.add-title {
    margin: 0 !important;
    color: #000;
    font-family: "Poppins500";
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.215px;
    margin-bottom: 5px !important;
}

.add-para {
    margin: 0 !important;
    color: #000;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 20px; /* 157.143% */
}
