.remove-btn {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.remove-btn-title, .remove-btn-count {
    margin: 0;
    color: var(--main-color);
    font-family: "Dmsans500";
    font-size: 14px;
    font-style: normal;
}

.remove-btn-image {
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
}
.remove-btn-image svg path {
    fill: var(--main-color);
}
.expired-title {
    color: rgb(236, 82, 82);
}

.deactivate-btn-image svg path {
    fill: rgb(236, 82, 82);
}
.deactivate-title {
    color: rgb(236, 82, 82);
}

@media (max-width: 1440px) {
    .remove-btn-title, .remove-btn-count {
        font-size: 13px;
    }
}