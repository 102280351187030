.note-container-parent {
    background: #fff;
    height: 100%;
}
.note-container {
    margin-top: 15px;
    background: #fff;
    padding: 20px 30px;
    border-radius: 5px;
}
.reminders {
    position: relative;
}

.dashboard-reminders {
    /* height: 250px; */
    /* overflow-y: scroll; */
}
.dashboard-reminders .note-content{
    width: 100% ;
}

.note-link-container {
    display: flex;
    justify-content: flex-end;
}

.note-link {
    margin-top: 40px;
    color: var(--main-color);
    font-family: "Dmsans700";
    font-size: 16px;
    letter-spacing: -0.32px;
    cursor: pointer;
}

.no-reminder-message {
    font-family: "Dmsans400";
    font-size: 18px;
}

/* Loading Skeleton */
.reminder-skelton {
    display: flex;
    align-items: start;
    gap: 20px;
} 

.first-reminder-skelton {
    width: 200px;
}
/* End Loading Skeleton */


.note-content .read-more {
    color: #2DB0D8 !important;
    font-family: "Dmsans700" !important;
    font-size: 12px !important;
    line-height: 28px; 
    letter-spacing: -0.24px;
    text-decoration-line: underline !important;
    cursor: pointer !important;
    padding-left: 10px;
}

/* .note-container .note-link {
    float: right !important;
    margin-top: 14px;
} */
.note-content:last-child {
    border-bottom: unset !important;
}



@media(max-width: 1450px) {
    .note-link {
        font-size: 15px;
    }
}

@media (max-width: 1200px) {
    .reminders {
        margin-top: 60px;
    }
    .reminder-skelton {
        gap: 80px;
    }
}

@media (max-width: 992px) {
    .reminder-skelton {
        gap: 40px;
    }
}

@media (max-width: 768px) {
    .reminder-skelton {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 576px) {
    .note-container {
        padding: unset;
    }
}

