.notes-skeleton {
    margin-top: 40px;
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.first-notes-skeleton {
    display: flex;
    align-items: start;
    gap: 80px;
}
.not-found{
        color: var(--Secondary-Grey-600, #5D5D5D);
        font-family: "Dmsans500";
        font-size: 15px;
        letter-spacing: -0.32px;
        margin: 0;
        text-align: center;
}


@media (min-width: 1200px) {
    .first-notes-skeleton div {
        width: 270px;
    }
    .second-notes-skeleton {
        width: 180px;
    }
}

@media (max-width: 1200px) {
    .first-notes-skeleton div {
        width: 250px;
    }
    .second-notes-skeleton {
        width: 160px;
    }
}

@media (max-width: 992px) {
    .first-notes-skeleton div {
        width: 180px;
    }
    .second-notes-skeleton {
        width: 120px;
    }
}

@media (max-width: 768px) {
    .first-notes-skeleton {
        flex-direction: column;
        gap: 20px;
    }
    .first-notes-skeleton div {
        width: 250px;
    }
    .second-notes-skeleton {
        width: 150px;
    }
}

@media (max-width: 476px) {
    .first-notes-skeleton div {
        width: 180px;
    }
    .second-notes-skeleton {
        width: 100px;
    }
}