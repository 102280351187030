.covered {
    padding-top: 40px;
    margin-top: 90px;
    background: url("../../../../Assets/images/Home/covered.png") no-repeat;
    background-size: cover;
}

.covered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.covered-para {
    margin-top: 16px;
    max-width: 60%;
    color: #313131;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 30px; 
    letter-spacing: 0.6px;
}

.covered-counts {
    margin-top: 40px !important;
    margin-bottom: -60px;
    width: 100%;
    padding: 25px 45px !important;
    background: var(--main-color);
}

@media (max-width: 1200px) {
    .covered-para {
        max-width: 75%;
        font-size: 15px;
    }
}

@media (max-width: 992px) {
    .covered-para {
        max-width: 85%;
    }
    .covered {
        margin-top:90px;
    }
}

@media (max-width: 768px) {
    .covered-para {
        max-width: unset;
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .covered-para {
        line-height: 27px;
        font-size: 13px;
    }
    .covered-counts {
        margin-top: 25px !important;
    }
    .covered-counts .col-lg-3:last-child .count::after {
        display: none;
    }
}

@media (max-width: 476px) {
    .covered-para {
        line-height: 26px;
        font-size: 12px;
    }
}