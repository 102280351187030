.note-pop-up {
    width: 316px;
    padding: 15px;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14);
}

.global-notes {
    display: flex;
    align-items: center;
    gap: 2px;
}

.note-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.note-header-title {
    margin: 0;
    color: #1B2559;
    font-family: "Dmsans500";
    font-size: 13px;
    letter-spacing: -0.28px;
}

.note-header-count {
    margin: 0;
    color: #7B7B7B;
    font-family: "Dmsans700";
    font-size: 13px;
    letter-spacing: -0.28px;
}

.see-all-notes-title {
    margin: 0;
    color: #1B2559;
    font-family: "Dmsans700";
    font-size: 10px;
    letter-spacing: -0.2px;
    text-transform: capitalize;
    cursor: pointer;
}

.note-text-input {
    margin-top: 10px;
    border-radius: 2px;
    border: 1px dashed #EFEFEF;
    outline: none;
    width: 100%;
    padding: 10px;
    font-size: 12px;
    resize: none;
}
.note-text-input::placeholder {
    color: rgba(173, 173, 173, 0.70);
    font-family: "Dmsans400";
    font-size: 12px;
    letter-spacing: -0.3px;
}

.note-footer {
    display: flex;
    justify-content: flex-end;
    /* justify-content: space-between;
    align-items: center; */
}

.note-delete-btn, .note-save-btn, .note-cancel-btn {
    border: none;
    outline: none;
    background: transparent;
    text-transform: uppercase;
    font-family: "Dmsans400";
    font-size: 12px;
    letter-spacing: -0.24px;
    transition: .3s ease;
}
.note-delete-btn:hover, .note-save-btn:hover, .note-cancel-btn:hover {
    transform: scale(1.1);
} 
.note-delete-btn {
    color: #EC5252;
}
.note-save-btn {
    color: #1B2559;
    font-family: "Dmsans500";
}
.note-cancel-btn {
    margin-right: 10px;
    color: #828282;
}
