.reports-content {
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
}

.reports-content:first-child {
    margin-top: 50px;
}
.reports-content:last-child {
    margin-bottom: 50px;
}
.reports-content:not(:last-child) {
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.reports-title {
    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Poppins500";
    font-size: 19px;
    letter-spacing: -0.3px;
}

.report-content {
    color: #393939;
    font-family: "Poppins500";
    font-size: 13px;
    letter-spacing: -0.18px;
}

.reports-para {
    color: #757575;
    font-family: "Poppins400";
    font-size: 13px;
    letter-spacing: -0.18px;
}

.reports-content .show-btns {
    top: 3px;
}

.reports-content .report-btns {
    top: 10px;
}

.regulation-type {
    margin-bottom: 0px;
  }

  .report-property {
    margin-bottom: 5px;
  }
.report-info::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 25%;
    top: 12%;
    left: -12px;
    border-radius: 1px;
    transform: translateY(-50%);
    background: var(--main-color);
}

@media (max-width: 1200px) {
    .reports-title {
        font-size: 17px;
    }
    .reports-para, .report-content {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .reports-title {
        font-size: 16px;
        max-width: 94%;
    }
    .report-content {
        font-size: 11px;
    }
    .reports-para {
        font-size: 11px;
        max-width: 94%;
    }
}

@media (max-width: 476px) {
    .reports-title {
        font-size: 15px;
    }
    .reports-para, .report-content {
        font-size: 10px;
    }
}