.add-result {
    color: var(--main-color);
    font-family: "Dmsans500";
    font-size: 16px;
    line-height: 20px; 
    border: none;
    background: none;
    padding-top: 20px;
}

.add-result img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: -6px;
}

@media (max-width: 567px) {
    .add-result {
        font-size: 14px;
    }
    .add-result img {
        width: 15px;
        height: 15px;
        margin-right: 8px;
        margin-top: -5px;
    }
}