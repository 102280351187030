.register-field {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.register-field .login-field {
    margin: 0;
}

.register-field-text {
    color: #101728;
    font-family: "Inter400";
    font-size: 14px;
    letter-spacing: 0.25px;
    margin-bottom: 8px;
}

.PhoneInputCountry {
    position: relative;
}
.PhoneInputCountry::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    background: #E5E5E5;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
}

.PhoneInputInput {
    border: none;
    outline: none;
    margin-left: 15px;
}

.login-submit.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

.message-confirm {
    text-align: center;
    font-family: "Dmsans300";
    color: #f00;
    font-size: 13px;
}