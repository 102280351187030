:root {
  --main-color: #2DB0D8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

ul {
  list-style: none;
}
body {
  font-family:'Poppins400';
}

/* Start Fonts */
/* Poppins */
@font-face {
  font-family: "Poppins100";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-Thin.ttf);
}
@font-face {
  font-family: "PoppinsItalic100";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-ThinItalic.ttf);
}
@font-face {
  font-family: "Poppins200";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-ExtraLight.ttf);
}
@font-face {
  font-family: "PoppinsItalic200";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "Poppins300";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-Light.ttf);
}
@font-face {
  font-family: "PoppinsItalic300";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-LightItalic.ttf);
}
@font-face {
  font-family: "Poppins400";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: "PoppinsItalic400";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-Italic.ttf);
}
@font-face {
  font-family: "Poppins500";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: "PoppinsItalic500";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-MediumItalic.ttf);
}
@font-face {
  font-family: "Poppins600";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "PoppinsItalic600";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Poppins700";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: "PoppinsItalic700";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-BoldItalic.ttf);
}
@font-face {
  font-family: "Poppins800";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-ExtraBold.ttf);
}
@font-face {
  font-family: "PoppinsItalic800";
  src: url(./Dashboard/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf);
}
/* End Poppins */

/* Dm Sans */
@font-face {
  font-family: "Dmsans100";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-Thin.ttf);
}
@font-face {
  font-family: "DmsansItalic100";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-ThinItalic.ttf);
}
@font-face {
  font-family: "Dmsans200";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-ExtraLight.ttf);
}
@font-face {
  font-family: "DmsansItalic200";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "Dmsans300";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-Light.ttf);
}
@font-face {
  font-family: "DmsansItalic300";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-LightItalic.ttf);
}
@font-face {
  font-family: "Dmsans400";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-Regular.ttf);
}
@font-face {
  font-family: "DmsansItalic400";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-Italic.ttf);
}
@font-face {
  font-family: "Dmsans500";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-Medium.ttf);
}
@font-face {
  font-family: "DmsansItalic500";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-MediumItalic.ttf);
}
@font-face {
  font-family: "Dmsans600";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-SemiBold.ttf);
}
@font-face {
  font-family: "DmsansItalic600";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Dmsans700";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-Bold.ttf);
}
@font-face {
  font-family: "DmsansItalic700";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-BoldItalic.ttf);
}
@font-face {
  font-family: "Dmsans800";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-ExtraBold.ttf);
}
@font-face {
  font-family: "DmsansItalic800";
  src: url(./Dashboard/assets/fonts/DmSans/DMSans-ExtraBoldItalic.ttf);
}
/* End Dm Sans */

/* Inter */
@font-face {
  font-family: "Inter100";
  src: url(./Dashboard/assets/fonts/Inter/Inter-Thin.ttf);
}
@font-face {
  font-family: "Inter200";
  src: url(./Dashboard/assets/fonts/Inter/Inter-ExtraLight.ttf);
}
@font-face {
  font-family: "Inter300";
  src: url(./Dashboard/assets/fonts/Inter/Inter-Light.ttf);
}
@font-face {
  font-family: "Inter400";
  src: url(./Dashboard/assets/fonts/Inter/Inter-Regular.ttf);
}
@font-face {
  font-family: "Inter500";
  src: url(./Dashboard/assets/fonts/Inter/Inter-Medium.ttf);
}
@font-face {
  font-family: "Inter600";
  src: url(./Dashboard/assets/fonts/Inter/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "Inter700";
  src: url(./Dashboard/assets/fonts/Inter/Inter-Bold.ttf);
}
@font-face {
  font-family: "Inter800";
  src: url(./Dashboard/assets/fonts/Inter/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: "Inter900";
  src: url(./Dashboard/assets/fonts/Inter/Inter-Black.ttf);
}
/* End Inter */
/* End Fonts */



/* Global Classes */
.box-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.results {
  margin-top: 30px;
  background: #fff;
  border-radius: 5px;
  padding: 20px 30px;
}

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  margin-top: 135px;
}

.password-field {
  background-image: url("./Dashboard/assets/images/svg/lock.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 20px !important;
  padding-left: 50px !important;
}

.user-field {
  background-image: url("./Dashboard/assets/images/svg/userInput.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 20px !important;
  padding-left: 50px !important;
}

.innerpages-container {
  padding: 40px 30px;
}

.innerpages-para {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--Secondary-Grey-600, #5D5D5D);
  text-align: justify;
  font-family: "Dmsans400";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.form-check-input:focus {
  box-shadow: none !important;
  border: none !important;
}

.popup-error {
  color: #EC5252;
  margin: 0 0 5px !important;
  font-family: "Dmsans300";
  font-size: 12px;
}

.show-btns {
  display: none;
  width: 10px;
  height: 18px;
  /* background-image: url("./Dashboard/assets/images/svg/dots-menu.svg"); */
  /* background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: cover; */
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 21px;
  z-index: 1;
}

.loading-search {
  font-family: "Poppins400";
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
}


.load-wrapp:last-child {
  margin-right: 0;
}

.ring-1 {
  width: 10px;
  height: 10px;
  margin: 0 auto;
  padding: 10px;
  border: 7px dashed var(--main-color);
  border-radius: 100%;
}

.load-4 .ring-1 {
  animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.no-results {
  font-family: "Poppins400";
  font-size: 16px;
  margin-top: 30px;
  text-align: center;
}

.react-loading-skeleton {
  --base-color: #eeeeee !important;
  --highlight-color: #dedede !important;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  border-radius: 11px !important;
  display: inline-flex;
  line-height: 10px !important;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  overflow: hidden;
  opacity: 0.5 !important;
}


.ReactModal__Content--after-open {
  inset: unset !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 350px !important;
  min-height: 153px !important;
  max-height: 275px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 8px !important;
  border: unset !important;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14) !important;
  overflow: unset !important;
  padding: 25px 15px !important;
  /* background: unset !important; */
}
.ReactModal__Content--after-open.dark-mode {
  position: absolute !important;
  background: #2f2f2f !important;
  left: 50% !important;
  top: 50% !important;
}

.ReactModal__Overlay {
  z-index: 200 !important;
}

@media (max-width: 1400px) {
  .box-container {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 1200px) {
  .box-container {
    flex-direction: row;
    align-items: center;
  }
  .innerpages-container {
    padding: 40px 0px;
  }
  .hide-date-responsive {
    display: none;
  }
}
/* End Global Classes */

@media (max-width: 992px) {
  .show-btns {
    display: block;
   
  }
}

@media (max-width: 776px) {
  
  .content-container {
    width: 100% !important;
  }
  
}

@media (max-width: 576px) {
  .hide-responsive {
    display: none;
  }
  .results {
    padding: unset;
    margin-top: unset;
  }
  .innerpages-container {
    padding: 40px 20px;
  }
  .innerpages-para {
    font-size: 14px;
    line-height: 22px;
  }
}

@media (max-width: 476px) {
  .innerpages-container {
    padding: 40px 15px;
  }
}



/* Animations */
@keyframes loadingD {
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
