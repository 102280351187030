.profile-form2 {
    margin-top: 14px;
    padding: 30px  50px;
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}
.tab-photo {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #181D27;
}
.profile-form-input {
    color: #181A1E !important;
    font-family: "Poppins400" !important;
    font-size: 13px !important;
    padding: 12px 12px !important;
    border-radius: 5px !important;
    border: 1px solid #D1D1D1;
    background: #FFF !important;
}
.profile-label {
    color: #565656;
    font-family: "Poppins400";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 7px;
}
.btn-user-details {
    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Poppins400";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 175% */
    letter-spacing: -0.32px;
    text-transform: uppercase;
    width:100%;
    cursor: pointer;
}
.button-container {
    float:right;
    margin-top: 30px;
}
.top-label {
    padding-top:20px;
}
.profile-form2 .PhoneInput {
    color: #181A1E !important;
    font-family: "Poppins400" !important;
    font-size: 13px !important;
    padding: 12px 12px !important;
    border-radius: 5px !important;
    border: 1px solid #D1D1D1;
    background: #FFF !important;
}

.profile-page-loading-container {
    height: 100%;
}

.profile-page-loading {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


.profile-page-loading .css-18lrjg1-MuiCircularProgress-root {
    color: var(--main-color) !important;
}