.pricing-page {
    margin-top:140px;
}
.plan-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.plan-title {
    font-size: 40px;
    font-family: 'Poppins400';
    color: #292929;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.plan-text {
    font-size: 16px;
    font-family: "Poppins400" !important;
    color:#313131;
    margin-bottom: 20px;
    line-height: 31px;
    text-align: center;
    max-width: 36%;
    letter-spacing: 0.58px;
}

.plan-date-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.plan-cart-container {
    padding-left: unset !important;
    padding-right: unset !important;
}

.plan-date-div .form-check-input {
    background-color: var(--main-color) !important;
    background-size: 19px 19px;
}
.plan-date-div .form-switch {
    margin-top: unset !important;
}
.plan-date-div .form-check-input {
    width: 55px !important;
}
.row-plan-cart {
    padding:0px 150px;
}

.plan-date {
    color: #101113;
    font-family: "Poppins500";
    font-size: 15px;
    font-style: normal;
    margin: 0;
}

@media (max-width: 1400px) {

}

@media (max-width: 1200px) {
    .row-plan-cart {
        padding:0px ;
    }
    .plan-text {
        max-width: 44%;
    }
}


@media (max-width: 992px) {
    .plan-text {
        max-width: 57%;
        line-height: 29px;
    }
    .plan-cart-container {
        margin-top: 40px !important;
    }
    .plan-div {
        margin-bottom: 10px;
    }
}

@media (max-width: 768px) {
    .plan-text {
        max-width: 80%;
    }
}

@media (max-width: 576px) {
    .plan-text {
        max-width: 90%;
    }
}

@media (max-width: 476px) {
    .plan-text {
        max-width: 94%;
        font-size: 13px;
        line-height: 27px;
    }
    .plan-title {
        font-size: 34px;
    }
}
@media (max-width: 400px) {
    .plan-text {
        max-width: 100%;
        font-size: 12px;
        line-height: 25px;
    }
    .plan-title {
        font-size: 30px;
    }
}