.custome-nav-link {
    display: flex;
    gap: 5px;
    align-items: center;
    position: relative;
}
/* .custome-nav-link::after {
    position: absolute;
    content: '';
    width: 1.5px;
    height: 60%;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #1B2559;
} */

.custome-nav-link-title {
    color: var(--Secondary-Grey-600, #A3AED0);
    font-family: "Dmsans500";
    font-size: 15px;
    letter-spacing: -0.32px;
    margin: 0;
}

.active-custome-nav-link .custome-nav-link-title {
    color: var(--main-color) !important;
}

.custome-nav-link-image svg {
    width: 20px !important;
    height: 20px !important;
    margin-top: -5px;
}

@media (max-width: 1300px) {
    .custome-nav-link-title {
        font-size: 13px;
    }
    .custome-nav-link-image svg {
        width: 18px !important;
        height: 18px !important;
    }
}
