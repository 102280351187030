.about-container {
    padding-top: 90px;
}

.polaris-title {
    color: #292929;
    font-size: 26px;
    font-family: "Poppins400";
    margin-bottom: 5px;
    text-transform: capitalize;
}

.polaris-text {
    color: #313131;
    font-size: 14px;
    font-family: "Poppins400";
    line-height: 25px;
    letter-spacing: 0.46px;
    text-align: justify;
    max-width: 95%;
}

.polaris-div {
    margin-top: 40px;
}

.polaris-img-div  {
    height: 100%;
    display: flex;
    align-items: end;
}

.polaris-img {
    width: 120%;
    object-fit: contain;
}

@media (max-width: 1400px) {
    .polaris-text {
        font-size: 13px;
    }
    .polaris-img-div {
        align-items: center;
    }
}

@media (max-width: 1200px) {
    .polaris-div {
        margin-top: 20px;
    }
  
}

@media (max-width: 992px) {
    .polaris-text {
        font-size: 12px;
    }
    .polaris-img-div {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
    .polaris-img {
        width: 100%;
    }
    .about-container {
        padding: 55px 0px;
    }
}

@media (max-width: 768px) {
    .polaris-text {
        font-size: 11px;
    }
}

@media (max-width: 576px) {
    .about-container {
        padding: 25px 0px;
    }
    .polaris-title {
        font-size: 22px;
    }
    .polaris-text {
        font-size: 10px;
        line-height: 21px;
        letter-spacing: unset;
        max-width: 100%;
    }
    .polaris-img {
        padding-bottom: 35px;
    }
}