.remaining-days {
    font-family: "Dmsans700";
    font-size: 14px;
    font-style: normal;
    letter-spacing: -0.28px;
}

@media (max-width: 1440px) {
    .remaining-days {
        font-size: 12px;
    }
}