.tab {
    cursor: pointer;
}


.active-tab .profile-tab {
background-color: #2DB0D8;
filter: drop-shadow(0px 4px 44px rgba(0, 0, 0, 0.06));
}
.active-tab  .chevron {
    fill:#fff;
}
.active-tab  .tab-title {
    color:#fff;
}
.active-tab  .tab-text {
    color:#fff;
}
.active-tab  .circle-icon {
    fill:#fff;
    opacity:10;
}

.profile-page-content-container {
    padding-left: unset !important;
    margin-left: -41px !important;
}
.profile-page-content {
    height: 100%;
    width: 111%;
    margin: 0 auto;
}

.profile-tab-button {
    display: none;
}


@media (max-width: 1700px) {
    .profile-page .dashboard-navbar {
            margin-left: unset !important;
    }
}


@media (max-width: 1400px) {
    .content-container-profile .dashboard-navbar {
        margin-left: 0px !important;
    }
    .content-container-profile {
        margin-left: unset !important;
    }
    .profile-page-content-container {
        margin-left: -16px !important;
    }
  }
  @media (max-width: 1200px) {
    .content-container-profile {
        margin-left: unset !important;
    }
    .profile-page-content {
        width: 104%;
    }
  }

  @media (max-width: 992px) {
    .profile-page-content-container {
        margin-left: unset !important;
        width: 100% !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }
    .profile-page-content {
        width: 100%;
    }

    .profile-tab-button {
        display: flex;
        justify-content: flex-end;
        /* position: absolute; */
    }

    .profile-page .content {
        margin-top: 50px;
    }

    .drawer-profile-tab-content {
        padding: 20px;
    }
  }




  .loading-container {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-container .ciruclar-spinner {
    color: var(--main-color) !important;
  }