.date-time-picker input{
    margin-top: 10px !important;
    width: 100% !important;
    padding: 10px 0 10px 12px !important;
    color: #828282 !important;
    font-feature-settings: 'clig' off, 'liga' off  !important;
    font-family: "Dmsans400" !important;
    font-size: 12px !important;
    letter-spacing: -0.3px !important;
    outline: none !important;
}
.date-time-picker fieldset {
    border-radius: 2px !important;
    border: 1px dashed #EFEFEF !important;
}
.date-time-picker .css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: var(--main-color) !important;
}
.date-time-picker .css-23p0if-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
    will-change: background-color;
    background-color: var(--main-color);
}
/* .css-3dah0e-MuiModal-root-MuiDialog-root {
    z-index: 33333333 !important; /* Adjust the z-index value as needed *
} */
/* .MuiDialog-root .MuiPickersModal-dialog {
    z-index:333333333 !important;
} */