.count {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.count-title {
    text-align: center;
    color: #FFF;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.6px;
}

.count-num {
    display: flex;
    justify-self: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: "Poppins200";
    font-size: 50px;
    text-transform: capitalize;
}

.count-num h1 {
    margin: 0 !important;
}

@media (max-width: 1400px) {
    .count-title {
        font-size: 15px;
    }
}

@media (max-width: 1200px) {
    .count-title {
        font-size: 12px;
    }
    .count-num {
        font-size: 38px;
    }
}

@media (max-width: 576px) {
    .count {
        margin-top: 20px;
    }
    .count::after {
        position: absolute;
        content: "";
        background: #d1d1d1;
        bottom: -10px;
        left: 50%;
        width: 40%;
        transform: translateX(-50%);
        height: 1px;
    }  
    .count-num {
        font-size: 34px;
    }
    .count-title {
        font-size: 14px;
    }
}

@media (max-width: 476px) {
    .count-title {
        font-size: 12px;
    }
    .count-num {
        font-size: 30px;
    }
}