.privacy-page {


}
.privacy-page-title {
    font-size: 40px;
    color:#292929;
    margin-bottom:10px;
    font-weight:400;
}
.privacy-date {
    color:#313131;
    font-size:15px;
    font-weight:400;
}
.privacy-page-text {
    color:#313131;
    font-size:15px;
    font-weight:400;
    margin:40px 0px;
}
.privacy-title {
    font-size:15px;
    font-weight: 700;
    color:#313131;
    margin-bottom:10px;
}
.privacy-text {
    font-size: 15px;
    color:#313131;
    font-weight:400;
    margin-bottom: 5px;
}
.privacy-text div {
    margin-bottom: 5px;
}

.privacy-text span{
    font-size:15px;
    font-weight: 700;
    color:#313131;
    margin-bottom:10px;
}

.privacy li {
    font-size: 15px;
    color:#313131;
    font-weight:400;
    margin-bottom: 5px;
}
.privacy ul {
    padding-left: 1.5rem;
    list-style-type: disc;
}
