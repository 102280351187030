.reminder-pop-up {
    width: 316px;
    /* height: 179px; */
    padding: 15px;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14);
}

.global-reminders {
    display: flex;
    align-items: center;
    gap: 2px;
}

.reminder-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reminder-header-title {
    margin: 0;
    color: #1B2559;
    font-family: "Dmsans500";
    font-size: 13px;
    letter-spacing: -0.28px;
}

.reminder-header-count {
    margin: 0;
    color: #7B7B7B;
    font-family: "Dmsans700";
    font-size: 13px;
    letter-spacing: -0.28px;
}

.see-all-reminders-title {
    margin: 0;
    color: #1B2559;
    font-family: "Dmsans700";
    font-size: 10px;
    letter-spacing: -0.2px;
    text-transform: capitalize;
    cursor: pointer;
}

.reminder-title-text {
    margin-top: 10px;
    width: 100%;
    padding: 10px 0 10px 12px;
    border-radius: 2px;
    border: 1px dashed #EFEFEF;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Dmsans400";
    font-size: 12px;
    letter-spacing: -0.3px;
    outline: none;
}
.reminder-title-text::placeholder {
    color: rgba(173, 173, 173, 0.70);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Dmsans400";
    font-size: 12px;
    letter-spacing: -0.3px;
}

.reminder-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reminder-delete-btn, .reminder-save-btn, .reminder-cancel-btn {
    border: none;
    outline: none;
    background: transparent;
    text-transform: uppercase;
    font-family: "Dmsans400";
    font-size: 12px;
    letter-spacing: -0.24px;
    transition: .3s ease;
}
.reminder-delete-btn:hover, .reminder-save-btn:hover, .reminder-cancel-btn:hover {
    transform: scale(1.1);
} 
.reminder-delete-btn {
    color: #EC5252;
}
.reminder-save-btn {
    color: #1B2559;
    font-family: "Dmsans500";
}
.reminder-cancel-btn {
    margin-right: 15px;
    color: #828282;
}

.reminder-text .date-picker {
    width: 100% !important;
    border: 1px solid #F4F4F4;
    margin-top: 10px;
    margin-bottom: 10px;
}

