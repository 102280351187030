/* BUTTON GLOW */
.btn-glow img {
    /* padding-left: 11px;
    padding-bottom: 5px; */
    width: 35px;
    padding-left: 4px;
    transition: 0.3s;
}

.btn-glow {
    color: #fff !important;
    border-radius: 60px;
    display: inline-block;
    background:#2DB0D8;
    background: -moz-linear-gradient(left, #2DB0D8 34%,#5949d1 186%);
    background: -webkit-linear-gradient(left,#2DB0D8 34%,#5949d1 186%);
    background: linear-gradient(to right, #2DB0D8 34%,#5949d1 186%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8a61f8', endColorstr='#ec5fe7',GradientType=1 );
    border: none;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 56px;
    letter-spacing: 0.05em;
    text-decoration: none !important;
    text-transform: uppercase;
    /* padding: 0 30px; */
    transition: 0.3s;
    -webkit-transition: 0.3s;
    box-shadow: none;
    animation-timing-function: ease-out;
    position: relative;
    border: 3px solid transparent; /* To make space for the glowing border */
    background-clip: padding-box;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: glow-without-hover 3s infinite;
}

.btn-glow:before {
    content: '';
    position: absolute;
    top: -2px; 
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 60px;
    background: linear-gradient(45deg, #2db0d8, #b5c2ff, #2db0d8, #b5c2ff);
    background-size: 300% 300%;
    z-index: -1;
    animation: borderMove 3s linear infinite;
}

@keyframes borderMove {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
}

.btn-glow:hover {
    box-shadow: 0 0 0 10px #3daaa200;
    transition: .8s;
    -webkit-transition: .8s;
    cursor: pointer;
    animation: glow 3s infinite;
}

@keyframes glow {
    0% { box-shadow: 0 0 20px #2db0d8; text-shadow: 0 0 0px #fff; }
    40% { box-shadow: 0 0 100px #2db0d8; text-shadow: 0 0 20px #fff;}
    100% { box-shadow: 0 0 20px #2db0d8c9; text-shadow: 0 0 1px #fff; }
}


@keyframes glow-without-hover {
    0% { box-shadow: 0 0 10px #2db0d8; text-shadow: 0 0 0px #fff; }
    40% { box-shadow: 0 0 20px #2db0d8; text-shadow: 0 0 20px #fff;}
    100% { box-shadow: 0 0 10px #2db0d8c9; text-shadow: 0 0 1px #fff; }
}

.btn-hover-shine {
    position: relative;
}

.btn-hover-shine:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    height: 100%;
    right: 80%;
    width: 1px;
    background-color: #ffffff00;
    box-shadow: 0 0 20px #ffd2fe00, 0 0 10px #ffd2fe00, 0 0 5px #ffd2fe00, 0 0 2px #ffd2fe00;
    opacity: 1;
}

.btn-hover-shine:hover:after {
    right: 2%;
    background-color: #ffffffff;
    box-shadow: 0 0 30px #ffd2feff, 0 0 15px #ffd2feff, 0 0 7px #ffd2feff, 0 0 3px #ffd2feff;
    opacity: 0;
    transition: 2s;
    -webkit-transition: 2s;
}

.bottom-dashboard-section {
    width: 100% !important;
    margin-top: 60px !important;
    padding-top: 60px;
    background-image: repeating-linear-gradient(
        to right,
        #A5A5A5,
        #A5A5A5 10px,
        transparent 10px,
        transparent 20px
    );
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

@media (max-width: 476px) {
    .btn-glow {
        width: 60px;
        height: 60px;
    }
}