.employee-div {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
/* .css-1pqm26d-MuiAvatar-img {
    object-fit: fill;
} */

.employee-list-container .btn-add-employee {
    border-radius: 5px;
    background: #212121;
    color: #FFF;
    padding: 8px 21px;
    font-family: "Poppins400";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: none;
    transition: .3s;
}

.btn-add-employee:hover {
    background: #292929;
    transition: .3s;

}

.employee-list-container .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: unset !important;
}

.note-employee {
    color: #000;
    text-align: center;
    font-family: "Poppins500";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.employee-list-container .css-rbjqkq-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    display: flex !important;
    align-items: center !important;
}


.employee-list-container .status {
    display: inline-flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: none;
    color: #fff;
    font-family: "Poppins400" !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}

.employee-list-content .enable {
    background: #2DB0D8 !important;

}

.employee-list-content .disable {
    background-color: #EC5252;
}

.profile-page-content {
    background: white;
    padding: 20px 30px;
}

.employee-list-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.loading-delete {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}