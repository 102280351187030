.hr-component-title {
    margin-top: 20px;
    color: #313131;
    text-align: center;
    font-family: "Poppins400";
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.7px;
}

.hr-component-image {
    border: 11px solid #000;
    border-radius: 28px;
}

.hr-component-image img {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

@media (max-width: 1400px) {
    .hr-component-title {
        margin-top: 18px;
        font-size: 19px
    }
}

@media (max-width: 1200px) {
    .hr-component-title {
        margin-top: 17px;
        font-size: 18px
    }
}

@media (max-width: 992px) {
    .hr-component-container {
        margin-top: 40px;
    }
    .hr-component-image {
        width: 80%;
        margin: 0 auto;
    }
    .hr-component-title  {
        margin-top: 15px;
        font-size: 17px;
    }
}

@media (max-width: 476px) {
    .hr-component-image {
        width: 100%;
    }
    .hr-component-title {
        margin-top: 13px;
        font-size: 15px;
    }
}