.book-demo-container {
    margin-top: 60px;
    position: relative;
}

.book-demo-title {
    color: #292929;
    font-family: "Poppins400";
    font-size: 43px;
    font-style: normal;
    text-transform: capitalize;
}

.book-demo-title span {
    color: var(--main-color);
    font-family: "Poppins600";
}

.book-demo-para {
    margin-top: 20px;
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.57px;
    max-width: 70%;
}

.book-demo-image {
    position: absolute;
    left: 0;
    width: 48%;
    z-index: -1;
}

.contact-input-title span {
    color: #EC1C24;
}

.PhoneInput {
    border-radius: unset !important;
    height: 46px !important;
}


@media (max-width: 1200px) {
    .book-demo-title {
        font-size: 38px;
    }
    .book-demo-para {
        font-size: 14px;
        line-height: 22px;
        max-width: 90%;
        margin-top: 16px;
    }
}

@media (max-width: 992px) {
    .book-demo-form {
        margin-top: 400px
    }
    .book-demo-image {
        width: 550px;    
    }
    .book-demo-para {
        max-width: 100%;
        font-size: 15px;
        line-height: 24px;
    }
}

@media (max-width: 768px) {
    .book-demo-form {
        margin-top: 357px;
    }
    .book-demo-image {
        width: 480px;
    }
    .book-demo-para {
        font-size: 14px;
        line-height: 23px;
    }
    .book-demo-title {
        font-size: 34px;
    }
}

@media (max-width: 576px) {
    .book-demo-title {
        font-size: 30px;
    }
    .book-demo-para {
        font-size: 13px;
        line-height: 21px;
    }
    .book-demo-image {
        width: 390px;
    }
    .book-demo-form {
        margin-top: 291px;
    }
}

@media (max-width: 476px) {
    .book-demo-form {
        margin-top: 250px;
    }
    .book-demo-image {
        width: 340px;
    }
    .book-demo-para {
        margin-top: 13px;
        font-size: 12px;
        line-height: 20px;
    }
    .book-demo-title {
        font-size: 26px;
    }
}