.sidebtn {
    padding: 10px 12px;
    width: 85%;
    margin: 20px auto 0;
    margin-top: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
}
.active-sidebtn {
    background: rgba(45, 176, 216, 0.14) !important;
}

.sidebtn:hover{
    transition: .3s;
    background: rgba(128, 128, 128, 0.082);

}

.sidebtn-image {
    width: 25px;
    height: 25px;
    margin-bottom: 4.5px;
    display: flex;
    justify-content: center;
}

.sidebtn-title {
    color: var(--Secondary-Grey-600, #A3AED0);
    font-family: "Dmsans500";
    font-size: 16px;
    font-style: normal;
    letter-spacing: -0.32px;
    margin: 0 !important;
}

.active-sidebtn .sidebtn-title {
    font-family: "Dmsans500" !important;
    color: #2DB0D8 !important;
}

@media (max-width: 828px) {
    /* .sidebtn-title {
        display: none;
    }
    .sidebtn {
        width: 58px;
        justify-content: center;
    } */
}

@media (max-width: 576px) {
    .sidebtn-title {
        display: block;
    }
    .sidebtn {
        width: 85%;
        justify-content: start;
    }
}