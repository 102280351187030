/*notification*/

.notifications-center-container .css-1mcnwpj-MuiList-root {
    
    max-height: 500px;
}

.notifications-center-container ::-webkit-scrollbar {
    width: 3px; 
  }
  
  .notifications-center-container ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }

  .notifications-center-container ::-webkit-scrollbar-thumb {
    background: #b6b6b6; 
    border-radius: 10px; 
  }
  
  .notifications-center-container ::-webkit-scrollbar-thumb:hover {
    background: #959595;
  }
  
.notifications-center-list {
    max-height: 600px;
    overflow-y: scroll;
    
}

.notification-header-right {
    display: flex;
    gap: 5px;
    align-items: center;
}
.notification-container {
    padding-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
}
.notification-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding-bottom: 1px;
}

.notification-content {
    color: #898989;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.notification-content a {
    color: #2DB0D8 !important;
    text-decoration: underline !important;
    cursor: pointer;
    transition: .3s;
}

.notification-content a:hover {
    color: #219cc1 !important;
    transition: .3s;

}

.notification-header-title span {
    color: #565656;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.notification-header-title {
    display: flex;
    gap: 7px;
}

.notification-header-title img {
    width: 14px;
}

.notification-header-read-status {
    height: 6.5px;
    width: 6.5px;
    border-radius: 50%;
    margin-top: 3px;
}

.notification-content-container {
    padding-bottom: 19px;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
}

.notification-time {
    color: #898989;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

/* .notifications-center-content .notification-content-container:last-child {
    border-bottom: none;
  } */

.notification-content-container.no-border {
    border: none !important;
    padding-bottom: 0px;
}

.notification-button {
cursor: pointer;
}