.main-title {
    margin: 0;
    color: #292929;
    font-family: "Poppins400";
    font-size: 38px;
    line-height: 58px; 
    text-transform: capitalize;
}

.main-title span {
    color: #2DB0D8;
    font-family: "Poppins500";
    font-size: 70px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

@media (max-width: 1400px) {
    .main-title {
        font-size: 36px;
    }
}
@media (max-width: 1200px) {
    .main-title {
        font-size: 34px;
    }
}
@media (max-width: 992px) {
    .main-title {
        font-size: 32px;
    }
}
@media (max-width: 768px) {
    .main-title {
        font-size: 30px;
    }
}
@media (max-width: 576px) {
    .main-title {
        font-size: 28px;
    }
}
@media (max-width: 476px) {
    .main-title {
        font-size: 26px;
    }
}
