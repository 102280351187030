.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: space-between;
}

.title-fixed {
    position: sticky;
    z-index: 49;
    background: #F4F7FE;
    padding: 18px 0;
    top: 75px;
    border-bottom: 1px solid #ccc;
    transition: .3s;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.title-h {
    margin: 0 !important;
    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Dmsans700";
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
}

.info-tooltip {
    background: #000;
    color: #fff;
    max-width: 250px;
    max-height: 300px;
    z-index: 100;
    border-radius: 5px;
}

.title-btn {
    display: none;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: rgba(45, 176, 216, 0.19);
    background-image: url("../../assets/images/svg/titleBtn.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.title-btn-content {
    position: absolute;
    right: 31px;
    top: 20px;
    padding: 15px;
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14); 
    z-index: 10;   
}
.filters-sm {
    padding:13px 12px;
}

@media (max-width: 1200px) {
    .title-btn {
        display: block;
    }
}

@media (max-width: 1200px) {
    .filter-title-sm {
        padding-top: 20px;
       }
    }


@media (max-width: 576px) {
    .filter-title-sm {
        padding-top: 20px;
       }
    .title-container {
        border-bottom: 1px solid #EFEFEF;
        padding-bottom: 10px;
    }
    .title-fixed {
        background: #fff !important;
        transition: .3s;
    }
    .title-btn-content {
        right:60px;
        top: 0px;
        z-index: 1400;
    }
}

