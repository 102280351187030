.website-nav {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    top: -40px !important;
    margin-top: 40px !important;
    background: transparent !important;
    transition: top .3s ease;
    z-index: 100;
}
.navbar-toggler {
    border:unset !important;
}

.website-nav .nav-link {
    color: #3E3E3E !important;
    font-family: "Poppins400" !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    /* margin-left: 20px !important; */
}

/* .website-nav .navbar-nav {
    margin-left: 80px !important;
} */

.inner-page-nav .nav-link {
    color:#fff !important;
}
.inner-page-nav .active-website-nav::after {
    background: #fff !important;
}

.inner-page-nav {
    border-bottom: 1px solid #D2D2D2 !important;
    padding: 15px 0 20px !important;
}

.website-navbar-container {
    border-bottom: 1px solid #D2D2D2 !important;
    padding: 15px 5px 20px !important;
}



.website-nav .active-website-nav {
    position: relative;
    font-family: "Poppins700" !important;
}
.website-nav .active-website-nav::after {
    content: "";
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    background: #3E3E3E;
    height: 2px;
}

.navbar-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.navbar-login {
    color: var(--main-color) !important;
    font-family: "Poppins500";
    font-size: 14px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    padding-right: 10px;
}

.navbar-book {
    padding: 10px 15px; 
    background: var(--main-color);
    color: #fff;
    border: none;
    outline: none;
    font-family: "Poppins500";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    transition: .3s ease;
    border: 1px solid transparent;
}
.navbar-book:hover {
    background: #fff;
    color: var(--main-color);
    border: 1px solid var(--main-color);
}

.navbar-btns  .profile-name {
    color: #1F384C;
    font-family: "Dmsans400";
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
}

.navbar-btns button  {
    margin-left: 0 !important;
}


.navbar-toggler-icon {
    background-image: url("../../Assets/images/svg/menu-black.svg") !important;
}

/* Scroll Navbar */
.website-nav-scrolled {
    position: fixed !important;
    background: #212121 !important;
    top: 40px !important;
    margin-top:  -40px !important;
    transition: top .3s ease;
    box-shadow: 0 8px 16px rgba(33, 33, 33, 0.5);
}
.website-nav-scrolled .language-switcher-btn {
    color: #fff !important;
}
.inner-page-nav .language-switcher-btn {
    color: #fff !important;
}
.website-nav-scrolled .navbar-btns  .profile-name {
    color: white;
}
.website-nav-scrolled .website-navbar-container {
    border-bottom: unset !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
    padding-right: calc(var(--bs-gutter-x)* .5) !important;
    padding-left: calc(var(--bs-gutter-x)* .5) !important;
}

.website-nav-scrolled .nav-link {
    color: #fff !important;
}
.website-nav-scrolled .active-website-nav {
    font-family: "Poppins500" !important;
}
.website-nav-scrolled .active-website-nav::after {
    background: #fff !important;
}

.website-nav-scrolled .navbar-book  {
    border: 1px solid #212121;
}

.website-nav-scrolled .nav-logo {
    width: 120px;
}

.website-nav-scrolled .inner-page-nav {
    border-bottom: unset !important;
}
.website-nav-scrolled .inner-page-nav {
    border-bottom: unset !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
    padding-right: calc(var(--bs-gutter-x)* .5) !important;
    padding-left: calc(var(--bs-gutter-x)* .5) !important;
}
.inner-page-nav .nav-logo {
    width: 120px;
}

.website-nav-scrolled .navbar-toggler-icon {
    background-image: url("../../Assets/images/svg/menu-white.svg") !important;
}
.inner-page-nav .navbar-toggler-icon {
    background-image: url("../../Assets/images/svg/menu-white.svg") !important;
}
.website-nav-scrolled .navbar-toggler {
    border: unset;
    box-shadow: none !important;
}
.inner-page-nav .navbar-toggler {
    border: unset;
    box-shadow: none !important;
}


.nav-link a {
    color: unset !important;
}

.navbar-toggler:focus-visible {
    outline: unset !important;
}

.inner-page-nav .profile-name {
    color: #fff !important;
}

.website-nav .MuiAvatar-root {
    background: #bdbdbd;
    text-transform: uppercase !important;
}

.navbar-toggler:focus {
    box-shadow: unset !important;
}

@media (max-width: 1200px) {
    .website-nav .nav-link {
        font-size: 13px !important;
    }
    .navbar-btns {
        gap: 5px;
    }
    .navbar-book {
        padding: 10px 9px;
    }

}

@media (min-width: 1400px) {
    .navbar-book {
        padding: 10px 30px ;
    }
}
@media (max-width: 768px) {
    .language-switcher   .MuiPopover-paper::before {
        display: none !important; /* Hides the triangle */
      }
}

@media (max-width: 992px) {
    .website-nav .nav-link  {
        font-size: 13px !important;
    }
    .website-navbar-container {
        padding: 12px 0 17px !important;
    }
    .website-nav .website-navbar-container {
        backdrop-filter: blur(4px) !important;
    }
    .website-nav .navbar-nav {
        margin-left: unset !important;
    }
    .website-nav .nav-link {
        margin-left: unset !important;
    }
    .website-nav .active-website-nav::after {
        display: none;
    }
    .navbar-btns {
        justify-content: start;
        /* margin-top: 10px; */
    }
    .website-nav {
        -webkit-backdrop-filter: blur(4px) !important;
        backdrop-filter: blur(4px) !important;
    }

}

@media (max-width: 576px) {
    .website-navbar-container {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }
    .website-nav-scrolled .website-navbar-container {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }
    .website-nav .inner-page-nav {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }
    .website-nav-scrolled .inner-page-nav {
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
    }
}