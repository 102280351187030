.question-container {
    margin-top: 90px;
    padding-top: 90px;
    background-image: repeating-linear-gradient(
        to right,
        #A5A5A5,
        #A5A5A5 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
}

.question-img {
    width: 90%;
    height: 100%;
    object-fit: fill;
} 

.question-img-div {
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
}

.question-img-div::after {
    position: absolute;
    content: "";
    opacity: 0.2;
    background: var(--main-color);
    left: -52px;
    bottom: -43px;
    width: 80%;
    height: 94%;
    z-index: -1;
}

.question-title {
    color: #292929;
    font-family: "Poppins400";
    font-size: 26px;
    text-transform: capitalize;
}

.question-text {
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.46px;
    margin: 20px 0 35px;
}

.accordion {
    padding-top: unset !important;
}
.accordion-item:last-child {
    border-bottom: none !important;
}




@media (max-width: 1400px) {
    .question-img-div::after {
        left: -40px;
        bottom: -39px;
    }
}


@media (max-width: 1200px) {
    .accordion {
        padding: 0px !important;
    }
    .accordion-title {
        font-size: 16px !important;
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
    .question-img-div::after {
        left: -28px;
        bottom: -28px;
    }
    .question-title {
        font-size: 24px;
    }
    .question-text {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.40px;
        margin: 8px 0 20px;
    }
}

@media (max-width: 992px) {
    .question-container {
        margin-top: 0px;
        padding-top:55px;
    }
    .frequently-questions {
        /* text-align: center; */
    }
    .accordion-title {
        font-size: 17px !important;
    }
    .question-title {
        margin-top: 90px;
      }
      .question-img-div {
        justify-content: center;
      }
      .question-img-div::after {
        left: -15px;
        bottom: -45px;
    }
}
@media (max-width: 768px) {
    .accordion-title {
        font-size: 16px !important;
    }
}
@media (max-width: 576px) {

    .accordion-title {
        font-size: 15px !important;
    }
    .question-img-div::after {
        left: -1px;
        bottom: -29px;
    }
    .accordion-content {
        line-height: 22px;
        font-size: 11px;
    }
}
@media (max-width: 476px) {
    .accordion-title {
        font-size: 11px !important;
    }
    .accordion-content {
        font-size: 10px !important;
    }
    .question-text {
        font-size: 10px !important;
        line-height: 20px;
    }
    .question-title {
        font-size: 20px;
    }
    .accordion-icon {
        margin-right: 10px;
    }
    .accordion-icon img {
        width: 10px;
    }
}
