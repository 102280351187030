.team-card-image {
    border-radius: 8px;
}

.team-card-img {
    width: 100%;
    object-fit: cover;
}

.team-card-title {
    color: #202020;
    font-family: "Poppins400";
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    margin: 17px 0 0 !important;
}

.team-card-second-title {
    color: #A8A8A8;
    font-family: "Poppins300";
    font-size: 16px;
    line-height: 24px; 
    text-transform: capitalize;
}
 
.team-card-para {
    max-width: 95%;
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.54px;
    margin-bottom: 5px !important;
}

.team-card-link {
    color: var(--main-color);
    font-family: "Dmsans700";
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.28px;
    text-decoration-line: underline !important;
    cursor: pointer;
}


@media (max-width: 1200px) {
    .team-card-para {
        max-width: unset;
        text-align: unset;
        font-size: 13px;
        line-height: 22px;
    }
    .team-card-title {
        font-size: 18px;
    }
    .team-card-second-title {
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .team-card {
        margin-top: 50px;
    }
}

@media (max-width: 768px) {
    .team-card-para {
        font-size: 12px;
        line-height: 21px;
    }
    .team-card-title {
        font-size: 17px;
    }
    .team-card-second-title {
        font-size: 13px;
    }
    .team-card-link {
        font-size: 13px;
    }
    .team-card {
        margin-top: 40px;
    }
}

@media (max-width: 576px) {
    .team-card-image {
        height: 450px;
        overflow: hidden;
    }
    .team-card-img {
        height: 100%;
        object-fit: cover;
    }
}