.chat-message {
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    text-transform: capitalize;
    position: relative;
    padding: 11px 0 11px 11px !important;
    transition: .3s ease;
}



.chat-message-title {
    color: #474747;
    font-family: "Inter400";
    font-size: 13px;
    margin-left: 8px !important;
}

.chat-dots {
    position: absolute;
    right: 0;
    top: -11px;
}
.chat-message .css-6hp17o-MuiList-root-MuiMenu-list {
    background: #2f2f2f !important;
}

.chat-props {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    min-width: 134%;
    margin-left: -15px;
    padding-left: 15px;
}

.chat-prop-title {
    font-size: 13px;
    font-family: "Poppins400";
    text-transform: capitalize;
    color: #767676;
    margin-bottom: 0 !important;
}
.chat-props.dark-mode .chat-prop-title {
    color: #ccc;
}

.chat-props svg {
    width: 18px;
    height: 18px;
    margin-top: -3px;
}
.chat-props svg path {
    fill: #767676 !important;
}
.chat-props.dark-mode svg path {
    fill: #ccc !important;
}

.active-chat {
    background: #ebebeb;
    border-radius: 4px;
    width: 96%;
}
.chat-dots {
    top: 0px;
    right: 6px;
}
.active-chat .chat-dots {
    right: -2px;
}

.active-chat.dark-mode {
    background: #434343;
}


.chat-delete {
    color: #f93a37;
}

.chatbot-menu {
    width: 12ch !important;
    border-radius: 10px !important;
}

.chat-icon {
    width: 18px;
    height: 18px;
}

.confirm-delete-container {
    width: 100%;
    min-height: 153px !important;
    padding: 20px;
}
.confirm-delete-container.dark-mode {
    background: rgb(47, 47, 47) !important;
}

.delete-img {
    margin-bottom: 20px;
}
.confirm-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.confirm-delete {
    color: #fff;
    font-family: "Poppins400";
    font-size: 13px;
    background: #f93a37;
    padding: 6px 25px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    text-align: center;
    width: 120px;
    height: 40px;
}
.activate {
    background-color: var(--main-color);;
}
.deactivate {
    background: #EC5252;
}

.confirm-delete-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
}

.cancle-delete {
    color: #000;
    font-family: "Poppins400";
    font-size: 13px;
    background: #F1F1F1;
    padding: 6px 25px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    text-align: center;
    width: 120px;
    height: 40px;
}

.delete-title {
    margin: 0 !important;
    color: #000;
    font-family: "Poppins500";
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.215px;
    margin-bottom: 5px !important;
}
.confirm-delete-container.dark-mode .delete-title {
    color: #fff !important;
}

.delete-para {
    margin: 0 !important;
    color: rgba(84, 89, 94, 0.60);
    font-family: "Poppins400";
    font-size: 14px;
    line-height: 20px; /* 157.143% */
}

.custom-modal-overlay {
    background: #0000009c !important;
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0px;
}

@media (max-width: 1400px) {
    .chat-message-title {
        font-size: 13px;
    }
}





/* disable delete btn */
/* .MuiList-root .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:last-child {
    cursor: default !important;
    pointer-events: none !important;
}
.MuiList-root .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:last-child:hover {
    background-color: unset !important;
} */