.menudots-sm {
    display: flex;
    align-items: start;
}
@media (max-width: 768px) {
    .menudots-sm {
        position: absolute;
        top: -55px;
        right:0px;
    }
}