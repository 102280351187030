.check-email-container{
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.check-email-image {
    margin: 0 auto;
    width: 55px;
    border-radius: 5px;
    background: rgba(45, 176, 216, 0.14);    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}

.check-email-title {
    margin-top: 20px;
    text-align: center;
    color: #000;
    font-family: "Dmsans700";
    font-size: 23px;
    text-transform: uppercase;
}

.check-email-para {
    margin: 0;
    color: #777;
    text-align: center;
    font-family: "Dmsans400";
    font-size: 14px;
    line-height: 20px; 
}

.recive-code {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.recive-code p {
    margin: 0;
    color: var(--black-9001-a-1-a-1-a, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Dmsans400";
    font-size: 12px;
    font-style: normal;
}
.recive-code a {
    color: #101728;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Dmsans700";
    font-size: 12px;
}

.code-digits {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.code-digit {
    border-radius: 5px;
    width: 45px;
    height: 45px;
    border: 1px solid #D2D2D2;
    text-align: center;
}