.date-picker {
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    border: none;
    padding: 13px 12px;
    outline: none;
    width: 280px;
    font-size: 13px;
    font-family: "Dmsans500";
}

.date-picker-wrapper {
    width: 280px !important;
    position: relative !important;
}

.rdrCalendarWrapper {
    border-radius: 5px;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.14) !important;
}
.rdrMonth {
 
}

.rdrDefinedRangesWrapper {
    display: none;
}

.rdrDateRangePickerWrapper {
    position: absolute;
    z-index: 10;
}

.rdrDateDisplayWrapper {
    display: none;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    background: var(--main-color) !important;
}

.reset-button {
    background: transparent;
    border: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}