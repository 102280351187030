.recharts-wrapper {
    width: 100% !important;
    border-radius: 5px !important;
    background: #fff !important;
    display: flex;
    justify-content: center;
}
.chart-container {
    height: 100%; /* This will take the full height of its parent */
  }

  /* .recharts-responsive-container {
    height: 200px !important;
  } */

  .responsive-container {
    height: 360px !important;
    
  }
  .charts-skeleton {
    width: 65px;
  }
  .charts-skeleton .react-loading-skeleton{
    border-radius: 0px !important;
  
  }
 .chart-container .reminder-skelton {
  transform: rotate(180deg);
  justify-content: center;
 }

 @media (max-width: 1450px ) {
  .recharts-wrapper {  
      height: 371px !important;
  }
  .chart-column {
      width: 32px !important;
  }
  .recharts-surface {
      width: 97% !important;
  }
}
 @media (max-width: 1300px){
  .charts-skeleton {
    width: 50px;
  }
  .charts-skeleton .react-loading-skeleton {
    width: 50px !important;
  }
}
  
  @media (max-width: 1200px) {
    .responsive-container {
      height: 218px !important; 
      margin-left: -15px;
    }
  }


@media (max-width: 768px){
  .charts-skeleton {
    width: 35px;
  }
  .charts-skeleton .react-loading-skeleton {
    width: 30px !important;
  }
  .chart-container .reminder-skelton  {
    flex-direction: row !important;
}
}

@media (max-width: 576px) {
  .responsive-container {
    height: 240px !important; /* Height for small screens */
  }
}
  

