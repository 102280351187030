.login-container {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.login-title {
    color: #000;
    font-family: "Dmsans700";
    font-size: 30px;
    font-style: normal;
    text-transform: uppercase;
}

.login-para {
    margin-top: 15px;
    color: #5D5D5D;
    font-family: "Dmsans400";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.login-fields {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: repeating-linear-gradient(
        to right,
        #A5A5A5,
        #A5A5A5 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
      margin-top: 20px;
}

.login-field {
    width: 100%;
    margin-top: 15px;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    font-size: 14px;
    font-family: "Inter400";
    outline: none;
}

.login-field::placeholder {
    color: #000;
    opacity: 0.4;
    font-family: "Inter500";
    font-size: 14px;
    letter-spacing: 0.25px;
}

.forget {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forget-link {
    color: #101728;
    font-family: "Dmsans400";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0.25px;
}

.remember {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.remember-label {
    color: #333;
    font-family: "Dmsans400";
    font-size: 14px;
    letter-spacing: 0.25px;
}

.login-btn {
    width: 100%;
    margin-top: 20px;
    background-image: repeating-linear-gradient(
        to right,
        #A5A5A5,
        #A5A5A5 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
}

.login-submit {
    margin-top: 25px;
    border: none;
    outline: none;
    width: 100%;
    background: var(--main-color);
    border-radius: 5px;
    color: #FFF;
    text-align: center;
    font-family: "Dmsans700";
    font-size: 14px;
    padding: 12px;
}

.sign-google {
    display: block;
    border-radius: 5px;
    margin-top: 10px;
    background: transparent;
    border: 1px solid #CFCFCF;
    outline: none;
    width: 100%;
    text-align: center;
    font-family: "Dmsans500";
    font-size: 14px;
    padding: 12px;
    color: #101728;
}

.sign-google img {
    margin-right: 8px;
}

.no-account {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.no-account p {
    color: var(--black-9001-a-1-a-1-a, #1A1A1A);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Dmsans500";
    font-size: 12px;
    margin: 0;
}

.no-account a {
    color: var(--main-color) !important;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Dmsans700";
    font-size: 12px;
    cursor: pointer;
}

.error-message {
    font-size: 12px;
    color: #EC5252;
    margin: 0;
    width: 100%;
    margin-top: 9px;
}

.login-submit.disabled {
    opacity: 0.1;
  }