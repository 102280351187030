.search-field {
    position: relative;
    margin: 20px 0 40px;
}

.search-input {
    width: 100%;
    padding: 15px 20px;
    border-radius: 5px;
    border: 1px solid #c7c7c7;
    background-color: #FFF;
    color: #1B2559;
    font-family: "Dmsans500";
    font-size: 13px;
    letter-spacing: -0.28px;
}
.search-input::placeholder {
    color: #D7D7D7;
    font-family: "Dmsans500";
    font-size: 13px;
    letter-spacing: -0.28px;
}
.search-input:focus-visible {
    outline: 1px solid var(--main-color);
}

.search-input-btn {
    position: absolute;
    right: 20px;
    background: none;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
}