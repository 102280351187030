.profile-info {
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 23px 50px;
    position: relative;
}

.tab-photo {

color: #181D27;
font-family: "Poppins400";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 19px;
padding-bottom: 20px;
}


.profile-photo-div-parent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 23px;
    flex-wrap: wrap;
}

.profile-photo-div .custom-file-upload {
    border: unset;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.imageChange {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-photo-div .c1 {
    position: absolute;
    left: 48px;
    bottom: 0;
}

input[type="file"] {
    display: none;
}

.profile-name {
    color: #555;
    font-family: "Poppins400";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    margin-bottom: 0px !important;
}

.profile-email {
    color: var(--Gray-Light, #949494);
    font-family: "Poppins400";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

.profile-initial{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: var(--main-color);
    width: 100%;
    height: 100%;
    font-size: 32px;
    font-family: "Poppins400";
}

.profile-text-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.profile-photo-div {
    position: relative;
}

@media (max-width: 992px) {

    .profile-info .profile-photo-div-parent {
        gap: 20px;
    }

    .profile-info {
        padding: 23px;
    }

}