.accordion {
    padding:40px 0px;
}
.accordion-item {
    border: none !important;
    border-bottom: 1px solid #D9D9D9 !important;
  }
  
  .accordion-title {
    color:#313131;
    font-size: 18px;
    font-family: "Poppins400";
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  .accordion-title.expanded {
   border: none !important;
  }
  
  .accordion-icon {
    margin-right: 20px;
  }
  
  .accordion-content {
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 12px;
    font-style: normal;
    line-height: 25px; 
    letter-spacing: 0.46px;
    margin-left: 37px;
    margin-bottom: 10px;
  }

.accordion-item:last-of-type {
  border-bottom-right-radius: unset !important; 
  border-bottom-left-radius: unset !important;
}

.accordion-item {
  padding-bottom: 15px !important;
}

  @media (max-width: 992px) {
      .accordion {
        padding: 20px 0px;
     }
  }
