.province-container {
    display: flex;
    align-items: center;
}

.province-container-label {
    color: #CDCDCD;
    font-family: "Dmsans400";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.28px;
    text-transform: capitalize;
}