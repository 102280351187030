.regulation-container {
    background: #fff;
    border-radius: 5px;
    padding: 11px 40px 44px;
    position: relative;
    width: 88%;
    margin: -60px auto 0;
}

.regulation-title {
    margin-top: 10px;
    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Dmsans500";
    font-size: 32px;
    letter-spacing: -0.7px;
    line-height: 45px;
}

/* .regulation-header-container {
    position: absolute;
    top: 15px;
    left: 24px;
    width: 92%;
} */

.regulation-header {
    position: sticky;
    position: -webkit-sticky;
    border-bottom: 1px solid #D3D3D3;
    background: #fff;
    z-index: 20;
    top: 73px;
    padding: 20px 0;
}

.detail-loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.detail-loading .css-18lrjg1-MuiCircularProgress-root {
    color: var(--main-color) !important;
}


.btn-show-more {
    background: none;
    border: none;
    color: var(--main-color);
    font-size: 15px;
    font-family: "Poppins400";
    text-decoration: underline;
}

@media (max-width: 1200px) {

}

@media (max-width: 768px) {
    .regulation-header {
        padding: 20px 0 10px;
    }
    .btn-show-more {
        font-size: 13px;
    }
}

@media (max-width:576px) {
    .regulation-container {
        padding: 11px 0px 44px;
    }
    .btn-show-more {
        font-size: 12px;
    }
}

@media (max-width: 476px) {
    .btn-show-more {
        font-size: 11px;
    }
}