.reset-password-image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: rgba(45, 176, 216, 0.14);
    width: 56px;
    height: 54px;
    padding: 15px 0;
    margin: 0 auto;
}

