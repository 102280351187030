.text {
    color: var(--Secondary-Grey-600, #5D5D5D);
    font-family: "Dmsans500";
    font-size: 13px;
    letter-spacing: -0.24px;
    margin: 0;
}

@media (max-width: 1450px) {
    .text {
        font-size: 13px;
    }
}

@media (max-width: 576px) {
    .text {
        font-size: 14px;
        line-height: 21px;
    }
}