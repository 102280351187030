.amendments-content {
    position: relative;
    border-top: 1px solid #EFEFEF;
    padding-top: 30px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between; 
    align-items: flex-start;
}

.amendments-content:first-child {
    border-top: unset !important;
}

.amendments-btns {
    position: relative;
    display: flex;
    align-items: center;
    gap: 15px;
}

.amendments-content .note-content {
    border-bottom: none !important;
    padding-bottom: unset !important;
}

@media (max-width: 992px) {
    /* .amendments-btns {
        display: flex;
        align-items: start;
        gap: 15px;
        padding: 20px;
        position: absolute;
        flex-direction: column;
        right: 10px;
        top: 45px;
        z-index: 10;
    } */
    .amendments-content .note-content {
        flex-wrap: wrap !important;
        width: 100%;
    }

    .amendments-btns {
        display: none;
    }
}


@media (max-width: 576px) {
    .amendments-content {
        justify-content: start;
        flex-direction: column-reverse;
        gap: 20px;
        /* padding-top: unset !important;
        margin-top: unset !important; */
    }
    .amendments-content .note-content {
        gap: unset !important;
        width: 100%;

    }
    .amendments-content:first-child {
       margin-top: 0px !important;
    }
    .amendments-btns {
        padding: 12px;
    }
}