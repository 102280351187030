.select-filter select {
    background: url("../../assets/images/svg/selectArrow.svg") no-repeat;
    background-position: calc(100% - 14px);
    background-size: 9px;
    background-color: #fff;
    border-radius: 5px;
    /* border: none; */
    padding: 13px 12px;
    outline: none;
    width: 250px;
    font-size: 13px;
    font-family: "Dmsans500";
    -moz-appearance: none; 
    -webkit-appearance: none;
    cursor: pointer;
}

.select-filter select::-ms-expand { 
    display: none; 
  }  