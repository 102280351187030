.add-btn {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}

.add-btn-title, .add-btn-count {
    margin: 0;
    color: var(--main-color);
    font-family: "Dmsans500";
    font-size: 14px;
    font-style: normal;
}

.add-btn-image {
    width: 12px;
}

@media (max-width: 1200px) {
    .add-btn-title {
        font-size: 13px !important;
    }
}

@media (max-width: 576px) {
    .add-btn-title, .add-btn-count {
        font-size: 15px !important;
    }
}