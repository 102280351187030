.amendments-info {
    /* margin-top: 40px !important; */
    padding-top: 40px;
    /* background-image: repeating-linear-gradient(
        to right,
        #A5A5A5,
        #A5A5A5 10px, 
        transparent 10px,
        transparent 20px 
      ); */
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
      display: flex;
      align-items: center;
      gap: 24px;
}

.amendments-checkboxes {
    display: flex;
    gap: 26px;
}

.amendments-checkboxes .form-check {
    position: relative;
}
.amendments-checkboxes .form-check::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    right: -13px;
    top: 50%;
    transform: translateY(-50%);
    background: #D9D9D9;
}

.amendments-checkboxes .form-check:last-child::after {
    display: none;
}

@media (max-width: 1200px) {
    .amendments-info {
        gap: 12px;
        display: none;
    }
}

@media (max-width: 992px) {
    .amendments-info {
        align-items: flex-start;
        flex-direction: column;
    }
}

@media (max-width: 776px) {
    .amendments-info {
        padding-top: 0px;
        margin-top: 0px !important;
        align-items: start;
       flex-direction: column;
    }
}

@media (max-width: 576px) {
  
    .amendments-checkboxes {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }    
    .amendments-checkboxes .form-check::after {
        display: none;
    }
}