.updates .chart-container {
    margin-top: 22px ;
}

@media (max-width: 1200px) {
    .updates {
        margin-top: 60px;
    }
}



@media (max-width: 1200px) {
    .recharts-wrapper {
        height: 215px !important;
    }
}

@media (min-width: 1201px) {
    .recharts-wrapper {
        height: 380px !important;
    }
}

@media (min-width: 1450px) {
    .recharts-wrapper {
        height: 390px !important;

    }
}

@media (min-width: 1555px) {
    .updates .chart-container {
        margin-top: 42px;
    }
}

