.regulation-skeleton {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 13px;
}

.first-regulation-skeleton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.first-regulation-skeleton span:first-child {
    width: 35%;
}
.first-regulation-skeleton span:last-child {
    width: 25%;
}


.second-regulation-skeleton {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

}
