.register-error {
    color: #ff3744;
    font-family: "Poppins400";
    font-size: 12px;
}

.book-demo-form {
    position: relative;
}

.loading-form {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    opacity: 0.5;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
}