.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: var(--main-color);
    color: #fff;
    font-family: "Poppins400";
}

.avatar-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
