/* Start jurisdiction modal */
.jurisdiction-modal {
    background: #222222;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--main-color);
    padding: 40px;
    border-radius: 5px;
    width: 75%;
}

.jurisdiction-modal-title {
    color: #E5E5E5;
    font-family: "Inter600";
    font-size: 24px;
    letter-spacing: 0.6px;
}
.jurisdiction-modal-title span {
    margin-left: 10px;
    color: #CDCDCD;
    font-family: "Dmsans400";
    font-size: 14px;
    letter-spacing: -0.32px;
    text-transform: capitalize;
}

.jurisdiction-modal-provinces-title {
    color: #E0E0E0;
    font-family: "Inter600";
    font-size: 17px;
    letter-spacing: 0.4px;
}

.jurisdiction-modal-provinces-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 50px; 

}
.province-group {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}
.province-group:not(:last-child):after {
    position: absolute;
    content: "";
    height: 85%;
    width: 1px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
}


.jurisdiction-modal-documents {
    padding-top: 22px;
    margin-top: 22px;
    border-top: 1px solid #2b2b2b;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
}
.jurisdiction-modal-selected-documents {
    margin-top: 20px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px;
}


.jurisdiction-modal-search {
    margin-top: 15px;
    position: relative;
    width: 100%;
}
.jurisdiction-modal-search-input {
    padding: 7px 15px;
    width: 100%;
    background: transparent;
    border-radius: 7px;
    border: 1.5px solid #3B3B3B;
    color: #fff;
    font-family: "Inter400";
    font-size: 12px;
}
.jurisdiction-modal-search-input::placeholder {
    color: #323232;
    font-family: "Inter400";
    font-size: 12px;
}
.jurisdiction-modal-search-input::-webkit-search-cancel-button {
    -webkit-appearance: none !important;
    appearance: none !important;
}
.jurisdiction-modal-search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}


.jurisdiction-modal-provinces-footer {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 7px;
}
.jurisdiction-modal-provinces-footer-btn {
    padding: 6px 30px;
    color: var(--main-color);
    font-family: "Dmsans400";
    font-size: 12px;
    letter-spacing: -0.28px;
    text-transform: capitalize;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 5px;
    border: 1px solid var(--main-color);
}
.jurisdiction-modal-provinces-footer-btn:last-child {
    background: var(--main-color);
    color: #fff;
}


@media(max-width: 1400px) {
    .jurisdiction-modal {
        width: 88%;
    }
  
}

@media (max-width: 1200px) {
    .jurisdiction-modal-provinces-container {
        grid-template-columns: repeat(4, 1fr);
        gap: unset;
    }
    .province-group:after {
        display: none;
    }
}

@media (max-width: 800px) {
    .jurisdiction-modal-provinces-container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 650px) {
    .jurisdiction-modal-provinces-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
/* End jurisdiction modal */