.announcements {
    padding: 40px 20px;
    border-radius: 5px;
    background: #FFF;
}

.announcements-title {
    margin-top: 10px;
    color: var(--Secondary-Dark-Grey-900, #1B2559);
    font-family: "Dmsans600";
    font-size: 17px;
    line-height: 25px;
    letter-spacing: -0.36px;
}

.announcements-para {
    color: #000;
    text-align: justify;
    font-family: "Dmsans300";
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.32px;
}
.announcements-para-title {
    width: 85%;
    font-family: "Dmsans600";
    color: #000;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.32px;
}

@media (max-width: 1200px) {
    .announcements-container {
        margin-top: 60px;
    }
}

@media (max-width: 576px) {
    .announcements {
        padding: 0 !important;
    }
    .announcements-para-title {
        font-size: 14px;
        line-height: 23px;
    }
    .announcements-para {
        margin-top: 10px;
        font-size: 14px;
        line-height: 23px;
    }
}