.new-register {
    width: 60%;
    margin: 0 auto;
    padding-top: 140px;
}

.new-register input[type="file"] {
    display: block;
}

@media (min-width: 100px) {
    .new-register .book-demo-form {
        margin-top: unset !important;
    }
}