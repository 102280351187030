.popup {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 100% !important;
}

.popup-container {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 13px;
}

.popup-content {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin-bottom: 20px;
}

.popup-title {
    margin: 0 !important;
    color: #000;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.215px;
}

.popup-para {
    margin: 0 !important;
    color: rgba(84, 89, 94, 0.60);
    font-family: "Poppins400";
    font-size: 14px;
}

.popup-btn {
    width: 86%;
    color: #FFF;
    font-family: "Poppins400";
    font-size: 14px;
    background: #000;
    padding: 8px 15px;
    border-radius: 8px;
    cursor: pointer;
}